import axios from 'axios';
import qs from 'qs';
import {
    AddFundRequest,
    AddFundResponse,
    DeleteFundRequest,
    GetBackTestsRequest,
    GetBackTestsResponse,
    GetFundOperationsRequest,
    GetFundOperationsResponse,
    GetFundRequest,
    GetFundResponse,
    GetFundsRequest,
    GetFundsResponse,
    GetOperationsRequest,
    GetOperationsResponse,
    UpdateFundRequest,
} from '../types/fund.types';

const baseUrl = '/api/funds';

export const GetFunds =
    async (request?: GetFundsRequest) => await axios.get<GetFundsResponse>(`${baseUrl}`, {
        params: request?.params,
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
    });

export const GetFund =
    async (request: GetFundRequest) => await axios.get<GetFundResponse>(`${baseUrl}/${request.params.oid}`);

export const DeleteFund = async (request: DeleteFundRequest) =>
    await axios.delete(`${baseUrl}/${request.params.fundId}`);

export const AddFund = async (request: AddFundRequest) =>
    await axios.post<AddFundResponse>(`${baseUrl}`, request.data);

export const UpdateFund = async (request: UpdateFundRequest) =>
    await axios.put(`${baseUrl}/${request.params.oid}`, request.data);

export const GetFundOperations = async (request: GetFundOperationsRequest) =>
    await axios.get<GetFundOperationsResponse>(`${baseUrl}/${request.params.oid}/operations`, { params: request.params  });

export const GetOperations = async (request?: GetOperationsRequest) =>
    await axios.get<GetOperationsResponse>(`api/operations`, { params: request?.params });

export const GetBackTests = async (request?: GetBackTestsRequest) =>
    await axios.get<GetBackTestsResponse>(`${baseUrl}/${request?.params?.oid}/backTests`, { params: request?.params });
