import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import * as React from 'react';
import { createContext, ReactElement, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

type DSD = React.Dispatch<React.SetStateAction<DialogProps>>;   // DSD -> Dispatch SetStateAction DialogProps

type DialogProps = {
    title: string;
    content?: string | ReactElement;
    open: boolean;
    yesCallback: ((close: () => void) => (void | null));
    noCallback: ((close: () => void) => (void | null));
};

const UpdateDialogContext = createContext<DSD>(() => { });

export const useDialogContext = () => useContext(UpdateDialogContext);

export const ErrorDialogContext: React.FC<{ children: React.ReactElement }> = ({ children }) => {

    const { t } = useTranslation();

    const [dialog, setDialog] = useState<DialogProps>({
        title: '',
        open: false,
        yesCallback: () => null,
        noCallback: () => null
    });

    const closeDialog = React.useCallback(() => {

        setDialog({
            ...dialog,
            open: false
        });
    }, [dialog]);

    return (
        <UpdateDialogContext.Provider value={setDialog}>
            {children}
            <Dialog
                sx={{
                    zIndex: 1,
                    '& .MuiDialogTitle-root': {
                        color: '#a53636',
                        borderBottom: '2px solid #a53636',
                        backgroundColor: 'white'
                    },
                }}
                open={dialog.open}
                fullWidth
                maxWidth="xs"
            >
                <DialogTitle>{dialog.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ pt: 2 }}>{dialog.content}</DialogContentText>
                </DialogContent>
                <DialogActions sx={{ backgroundColor: '#eee' }}>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => { dialog.noCallback(closeDialog); }}
                    >
                        {t("shared.no")}
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => { dialog.yesCallback(closeDialog); }}
                    >
                        {t("shared.yes")}
                    </Button>
                </DialogActions>
            </Dialog>
        </UpdateDialogContext.Provider>
    );
};
