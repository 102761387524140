import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { StyledDescriptionText } from '../../shared/components/Common/StyledDescriptionText';

export const Error500Page = () => {

    const { t } = useTranslation();
    const { state } = useLocation();

    return (
        <Box>
            <StyledDescriptionText>
                {t(`errorsPage.${state?.code || 500}_title`)}
            </StyledDescriptionText>
            <p>
                <b>{t(`errorsPage.code`)}: {state?.code || 500}</b>
            </p>
            <StyledDescriptionText>
                {t(`errorsPage.${state?.code || 500}_description`)}
            </StyledDescriptionText>
        </Box>
    );
};
