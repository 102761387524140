/*eslint react-hooks/exhaustive-deps: "off"*/
import { Box, Icon, Table as MuiTable, Paper } from '@mui/material';
import { CSSProperties } from '@mui/material/styles/createTypography';
import TableContainer from '@mui/material/TableContainer';
import * as React from 'react';
import { VdsPageNumberPagination } from './VdsPageNumberPagination';
import { ConditionalPageSizePagination } from './VdsPageSizePagination';
import { ConditionalActions } from './TFTableActions';
import { ConditionalTFTableHeader } from './TFTableHeader';
import { ConditionalTFTableBody } from './TFTableBody';
import { FaCheckCircle } from 'react-icons/fa';
import { AiFillCloseCircle } from 'react-icons/ai';

/** Tipologie di ordinamento selezionabili */
export type SortingTypes = 'Asc' | 'Desc';

/** Proprietà esposte dal riferimento alla tabella ( RT Reference type )*/
export type RT<R> = {
    reload: () => Promise<void> | null,
    // Dati della tabella 
    _table: {
        totalCount: number,
        setLoader: React.Dispatch<React.SetStateAction<boolean>>,
        filters: Filters,
    },
    // Metodi di utility
    _methods: {
        /** Metodo di utility permette l'applicazione di paginazione, ordinamento e filtri,
         * prendendo un filtres come parametro */
        apply: (data: R[], filters?: Partial<Filters>) => ({ data: R[], totalCount: number })
    }
};

/** Riferimento statico per l'esposizione di metodi interni alla tabella */
export type TFTableReferenceType<R> = React.ForwardedRef<RT<R>>;

//#region [Filters] Filtri applicabili sulla Tabella
export type Filters = {
    /** Numero della pagina corrente
     * undefined se pagination=false */
    pageNumber?: number;
    /** Numero delle righe per la pagina corrente
     * undefined se pagination=false */
    pageSize?: number;
    /** Testo inserito nel campo di ricerca della tabellla
     * undefined se searching=false */
    search?: string;
    /** Ordinamento selezionato, undefined se sorting=false
     *  NOTA: non è supportato l'ordinamento multiplo*/
    sorting?: {
        order: SortingTypes,
        field: string
    };
    /** Contenuto dei filtri singoli sulle colonne
     * undefined se enableFilters=false */
    filters?: {
        filter: string | null,
        field: string;
    }[]
}
export type Query = Filters;
//#endregion

export type DefaultColumnType<R> = {
    ignoreClick?: boolean;
    render: (data: R, queryProps?: Filters, config?: { row: number }) => (number | string | boolean | React.ReactElement);    // Contenuto da renderizzare nella cella del body
    // Proprietà di gestione dell'ordinamento
    sorting?: {
        // Definizione della tipologia di ordinamento di default
        default?: SortingTypes,
        // Campo di ricerca su cui eseguire l'ordinamento
        field: string
    };
    /** Proprietà di gestione di un eventuale filtro custom da utilizzare sull'intestazione della colonna ( vedi linee guida vapor )
     * Attualmente sono gestite due tipologie di filtro:
     * - Filtro con campo testuale libero 
     * - Filtro con selezione singola
     * */
    filter?: { field: string } & (
        { type: 'text' } |
        { type: 'select', options: { text: string, value: string }[] }
    )
};

export type ActionColumnType<R> = {
    icon: React.ReactElement | DefaultColumnType<R>['render'];                           // Icona da visualizzare nella cella
    onClick: (data: R, filters?: Filters) => void;   // Evento di click da applicare all'icona
    disabled?: (data: R) => boolean;                    // se true disabilita il pulsante nella cella
    hidden?: (data: R, _c: { row: number }) => boolean;                    // se true nascondi il pulsante nella cella
};

//#region Proprietà applicabili alla tabella
export type TFTableProps<R> = {

    //#region [CONFIGURATIONS] Columns
    /** Configurazione delle regole di visualizzazione delle celle della tabella.
     * La cella può essere creata scegliendo tra due varianti: 
     * - Un render che restituisce il contenuto della cella da visualizzare
     * - Un azione che permette di configurare rapidamente il pulsante con i relativi eventi ( utile per gestire cestini, configurazioni, ecc... )
     * */
    columns: (((DefaultColumnType<R> | ActionColumnType<R>) & {
        title?: () => (number | string | boolean | React.ReactElement);           // Contenuto da renderizzare nella cella dell' intestazione
        tooltip?: string | ((data: R) => string);    // Tooltip da visualizzare sull'intera cella/pulsante
        style?: CSSProperties;              // Stile generico da applicare sulla cella nel body
        thStyle?: CSSProperties;            // Stile generico da applicare sulla cella dell' intestazione
    }) | null)[];
    //#endregion

    //#region [CONFIGURATIONS] Actions
    /** Configurazione delle azioni da visualizzare al di sotto della sezione di ricerca.
     * Le azioni possono essere restituite come null, per agevolare il controllo tramite variabili o rules */
    actions?: ({
        tooltip?: string | ((data: R) => string);                      // Tooltip da visualizzare sul pulsante
        icon: React.ReactElement;                           // Icona da visualizzare nella cella
        onClick: (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>, filters?: Filters) => void;   // Evento di click da applicare all'icona
        disabled?: boolean;                                 // Se true disabilita il pulsante
        disableIfBodyIsEmpty?: boolean;                     // Se true disabilita il pulsante; ( diventa: true solo se non ci sono dati da visualizzare nel body della tabella )
    } | null)[];
    //#endregion

    /** Filtri da utilizzare per inizializzare la tabella con i dati relativi a filtri applicati e paginazione 
     * Nota: Utile per gestire il torna indietro tra sezioni che non condividono lo stesso route */
    initialize?: Filters;

    //#region [CONFIGURATIONS] Events
    // Eventi generici applicabili alle varie sezioni della tabella
    events?: {
        /** Evento di click sulla riga della tabella.
         * Nota: l'evento verrà scatenato al click sulla cella nel body */
        onRowClick?: (row: R, queryProps?: Filters) => void;
    }
    //#endregion

    //#region [CONFIGURATIONS] Options
    // Opzioni di configurazione generali da applicare alle funzionalità della tabella
    options?: {
        // Imposta manualmente la riga selezionata
        selectedRow?: (data: R) => boolean,
        // Disabilita manualmente gli eventi sulla riga
        disableRow?: (data: R, _c: { row: number }) => boolean,
        // Imposta manualmente lo stile delle righe
        rowStyle?: (data: R, _c: { row: number }) => CSSProperties,
        /** Configurazione delle traduzioni da utilizzare nelle sezioni della tabella 
         * Nota: Di default viene utilizzata la lingua inglese */
        localize?: {
            placeholder?: string;
            noData?: string;
            search?: string;
            rowsForPage?: string;
            displayedElementsSeparator?: string,
            resetFilters?: string,
            descriptionText?: string,
        },
        style?: CSSProperties;
        // Se true abilita la paginazione, di default true
        usePagination?: boolean;
        // Se true abilita la ricerca, di default true
        useSearch?: boolean;
        // Larghezza minima 
        minWidth?: CSSProperties['minWidth'];
        // ⚠Temporaneo: Aggiustamento dello stile delle celle del filtro
        filtersAdjustmentStyle?: CSSProperties;
    };
    //#endregion

    //#region ⚠TO DO
    /** Prossime funzionalità da integrare
     * - Gestione del pannello di dettaglio
     * - Gestione dei filtri custom non applicabili alla tabella ( vedi linee guida vapor )
     * */
    //#endregion

    // Riferimento statico per l'esposizione di metodi interni
    ref?: TFTableReferenceType<R>;

    // Lista di dipendenze che vincolano il reload dei dati nella tabella
    dependencyList?: React.DependencyList;
} & ({
    // Set di dati da utilizzare per la creazione delle righe nella tabella
    data: R[];
} | {
    // Riferimento statico per l'esposizione di metodi interni
    ref?: TFTableReferenceType<R>;
    // Promise che restituisce il set di dati da utilizzare per la creazione delle righe nella tabella
    data: (query?: Filters) => Promise<{ totalCount?: number; data: R[]; }>;
});
//#endregion

//#region Type guards

/** Verifica il set di dati passato come parametro, restituisce true se column è di tipo DefaultColumnType<R> */
function dataTypeGuard<R>(data: R[] | ((query?: Filters) => Promise<{ totalCount?: number; data: R[]; }>)): data is R[] {

    return (data as R[]).map !== undefined;
}

//#endregion

export type VTP<R> = TFTableProps<R> & { ref?: TFTableReferenceType<R> };

// Applica la paginazione ad una lista generica 
const applyPagination: <R>(filters: Filters, data: R[]) => R[] = (filters, data) => {

    const
        _pageNumber = filters.pageNumber,
        _pageSize = filters.pageSize;

    if (_pageNumber && _pageSize) {

        let _data = [];

        const indexStart = ((_pageNumber - 1) * _pageSize);
        const indexEnd = indexStart + _pageSize;

        _data = data.slice(indexStart > 0 ? indexStart : 0, indexEnd);

        return _data;

    } else {

        return data;

    }
}

// Applica i filtri per colonna ad una lista generica 
const applyFilters: <R>(filters: Filters, data: R[]) => R[] = (filters, data) => {

    if (filters?.filters?.length) {

        return data.filter((data: any) => {
            let _c = true;
            filters?.filters?.forEach((filter) => {
                if (filter.filter && !String(data[filter.field]).toLowerCase().includes(filter.filter.toLowerCase())) {
                    _c = false;
                    return;
                }
            });
            return _c;
        });
    } else {

        return data;
    }
}

// Applica l'ordinamento per una colonna ad una lista generica 
const applySort: <R>(filters: Filters, data: R[]) => R[] = (filters, data) => {

    const _sorting = filters.sorting;
    let _field: any = filters.sorting?.field;

    if (_sorting?.order === "Asc" && _field) {

        _field = _field[0].toLowerCase() + _field.slice(1, _field.length);

        return data.sort((a: any, b: any) => String(a[_field] || '').localeCompare(String(b[_field] || '')));

    } else if (_sorting?.order === "Desc" && _field) {

        _field = _field[0].toLowerCase() + _field.slice(1, _field.length);

        return data.sort((a: any, b: any) => String(b[_field] || '').localeCompare(String(a[_field] || '')));

    } else {

        return data;

    }
}

/** Metodo di utility permette l'applicazione di paginazione, ordinamento e filtri,
 * prendendo un filtres come parametro.
 * Da utilizzare quando si vuole controllare lo stato dei dati nella tabella, partendo dai filtri applicati senza passarli ai servizi. */
const apply: <R>(data: R[], filters?: Partial<Filters>) => ({ data: R[], totalCount: number }) = (data, filters) => {

    if (filters) {
        // 1. Applica l'ordinamento
        let _sortedData = applySort(filters, data);

        // 2. Filtra i dati
        let _filteresData = applyFilters(filters, _sortedData);

        // 3. Applica la paginazione
        let _paginatedData = applyPagination(filters, _filteresData);

        return {
            // Dati filtrati e/o paginati e/o ordinati
            data: _paginatedData ?? [],
            // Totale dei dati filtrati
            totalCount: _filteresData.length ?? 0
        }
    }
    return {
        data,
        // Totale dei dati filtrati
        totalCount: data.length ?? 0
    }
}

export const BooleanCell = (check: boolean) => {

    return (
        <Icon sx={{ display: 'flex', alignItems: 'center', margin: 'auto', justifyContent: 'center' }}>
            {check ?
                <FaCheckCircle style={{ color: "#09822A", fontSize: '16px' }} /> :
                <AiFillCloseCircle style={{ color: "grey", fontSize: '18px' }} />}
        </Icon>
    );
}

function Table<R>(props: TFTableProps<R>, ref: TFTableReferenceType<R>) {

    const [data, setData] = React.useState<R[]>([]);
    const [totalCount, setTotalCount] = React.useState<number>(0);
    const [search, setSearch] = React.useState<string>(props.initialize?.search || '');
    const [pageNumber, setPageNumber] = React.useState<number>(props.initialize?.pageNumber || 1);
    const [pageSize, setPageSize] = React.useState<number>(props.initialize?.pageSize || (props.options?.usePagination ? 10 : 10000));
    const [loader, setLoader] = React.useState<boolean>(false);
    const [sorting, setSorting] = React.useState<Filters['sorting']>(props.initialize?.sorting || { field: '', order: 'Asc' });
    const [filters, setFilters] = React.useState<Filters['filters']>(props.initialize?.filters || []);

    const _columns = React.useMemo(() => props.columns.filter((column) => column), [props.columns]);

    const _filters = React.useMemo(() => {
        return {
            pageNumber,
            pageSize,
            search,
            sorting,
            filters,
        }
    }, [pageNumber, pageSize, search, sorting, filters]);

    const reload = React.useCallback(() => {

        try {
            if (dataTypeGuard(props.data)) {

                const { data, totalCount } = apply(props.data, _filters);
                console.dir('dataTypeGuard(props.data)');
                console.dir(props.data);
                console.dir(_filters);

                setData(data);
                setTotalCount(totalCount);

                return null;
            } else {

                setLoader(true);
                return props
                    .data(_filters)
                    .then(({ data, totalCount }) => {
                        setLoader(false);
                        // se totalCount non è definito applica la paginazione lato fe ma mantieni la gestione dei filtri
                        // ps: questa gestione serve per risolvere un caso trovato sull lcmsbo
                        //     tabella con ricerca lato be, paginazione e filtri lato fe
                        if (totalCount) {
                            setData(data);
                            setTotalCount(totalCount);
                        } else {
                            const applyResult = apply(data, _filters);
                            setData(applyResult.data);
                            setTotalCount(applyResult.totalCount);
                        }
                    })
                    .catch(() => {
                        setLoader(false);
                    });
            }

        } catch (e) {

            return null;
        }
    }, [_filters, props.data]);

    React.useImperativeHandle(ref, () => ({
        reload,
        // Dati della tabella 
        _table: {
            totalCount,
            setLoader,
            filters: _filters,
        },
        // Metodi di utility
        _methods: {
            apply
        }
    }), [_filters, props.data]);

    React.useEffect(() => {

        reload();
    }, [_filters, ...(props?.dependencyList || [])]);

    React.useEffect(() => {
        if (dataTypeGuard(props.data)) reload();
    }, [props.data]);

    return (
        <Box sx={{ my: 1 }}>
            {props.options?.localize?.descriptionText && (
                <span
                    style={{
                        fontSize: "14px",
                        color: "#5A6872",
                        lineHeight: "30px",
                        fontFamily: "Cairo SemiBold",
                        fontWeight: 600,
                        textAlign: "left"
                    }}
                >
                    {props.options.localize.descriptionText}
                </span>
            )}
            {props.actions?.length === undefined && props.options?.usePagination === false ? null : (
                <Box style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'end' }}>
                    {/* Actions */}
                    <ConditionalActions<R>
                        actions={props.actions}
                        columns={_columns}
                        totalCount={totalCount}
                        filters={_filters}
                        options={props.options}
                        resetFilter={() => {
                            // Se viene lanciato l'evento di reset il numero di pagina deve essere resettato
                            setFilters(filters ? [...filters?.map(({ field }) => ({ field, filter: '' }))] : []);
                            setSearch('');
                            setPageNumber(1);
                        }}
                    />
                    {/* Paginazione sezione di selezione del numero di righe */}
                    <ConditionalPageSizePagination
                        pageSize={pageSize}
                        totalCount={totalCount}
                        loader={loader}
                        rowsForPage={props.options?.localize?.rowsForPage}
                        usePagination={props.options?.usePagination}
                        submit={(filters) => {
                            // cambiando il numero di righe per pagina verrà resettata il numero di pagine
                            setPageSize(filters.pageSize || 10);
                            setPageNumber(1);
                        }}
                        displayedElementsSeparator={props.options?.localize?.displayedElementsSeparator}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    />
                </Box>
            )}
            <Paper sx={{ boxShadow: 'none', borderRadius: '0px' } }>
                <TableContainer sx={{ ...props.options?.style }}>
                    <MuiTable size="small" style={{ tableLayout: 'fixed', minWidth: props.options?.minWidth || 'auto' }} stickyHeader>
                        <ConditionalTFTableHeader<R>
                            columns={_columns}
                            options={props.options}
                            sorting={sorting}
                            filters={filters}
                            sortingSubmit={(filters: any) => {
                                setSorting(filters.sorting);
                            }}
                            textFilterSubmit={(field: any) => {

                                // Se viene lanciato l'evento di submit il numero di pagina deve essere resettato
                                const _filter = field.filters[0];
                                const _filters = filters?.filter(({ field }) => field !== _filter.field) || [];

                                _filters.push(_filter);
                                setFilters([..._filters]);
                                setPageNumber(1);
                            }}
                            selectFilterSubmit={(field: any) => {
                                // Se viene lanciato l'evento di submit il numero di pagina deve essere resettato
                                const _filter = field.filters[0];
                                const _filters = filters?.filter(({ field }) => field !== _filter.field) || [];
                                _filters.push(_filter);
                                setFilters([..._filters]);
                                setPageNumber(1);
                            }}
                        />
                        <ConditionalTFTableBody<R>
                            loader={loader}
                            totalCount={totalCount}
                            pageSize={pageSize}
                            columns={_columns}
                            options={props.options}
                            events={props.events}
                            data={data}
                            filters={_filters}
                        />
                    </MuiTable>
                </TableContainer>
            </Paper>
            <Box>
                <VdsPageNumberPagination<R>
                    options={props.options}
                    pageSize={pageSize}
                    pageNumber={pageNumber}
                    totalCount={totalCount}
                    loader={loader}
                    submit={({ pageNumber }) => {
                        setPageNumber(pageNumber || 1);
                    }}
                />
            </Box>
        </Box>
    );
}

export const TFTable = React.forwardRef(Table) as <R>(props: TFTableProps<R> & { ref?: TFTableReferenceType<R> }) => ReturnType<typeof Table>;
