import { Box, Button, IconButton, InputAdornment, Link, TextField } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import { routes } from '../../constants/appRoutes';
import { StyledDescriptionText } from '../../shared/components/Common/StyledDescriptionText';
import { useLoaderContext } from '../../shared/contexts';
import { CallRegistration, SignIn } from '../../shared/helpers/auth.services';
import { useForm } from '../../shared/hooks/useForm';
import { useStandardCatch } from '../../shared/hooks/useStandardCatch';
import { useTextFieldVisibility } from '../../shared/hooks/useTextFieldVisibility';

const useRegistrationPage = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const query = new URLSearchParams(location.search);
    const token = query.get("token") || '';
    const email = query.get("email") || '';

    const { setLoader } = useLoaderContext();
    const { baseCatch } = useStandardCatch();

    const { register, submit } = useForm({ newPassword: '', confirmPassword: '' });

    const handleSubmitForm = submit((state) => {
        setLoader(true);
        CallRegistration({
            data: {
                email,
                token,
                password: state.newPassword,
            }
        })
            .then(() => {
                SignIn({
                    data: {
                        email,
                        password: state.newPassword
                    }
                })
                    .then(() => {
                        setLoader(false);
                        navigate('/' + routes.protected, { replace: true });
                    })
                    .catch((ev) => {
                        setLoader(false);
                        baseCatch(ev);
                    });
            })
            .catch((ev) => {
                setLoader(false);
                baseCatch(ev);
            });
    })

    const handleRedirect = React.useCallback(() => {
        navigate(routes.loginRoute, { replace: true });
    }, [navigate]);


    return {
        helpers: {
            register
        },
        models: {
            userProfile: {
                email
            },
        },
        events: {
            handleSubmitForm,
            handleRedirect
        },
    }
}

export const RegistrationPage = () => {

    const { t } = useTranslation();
    const passwordVisibility = useTextFieldVisibility();
    const confirmPasswordVisibility = useTextFieldVisibility();

    const {
        helpers: {
            register
        },
        models: {
            userProfile
        },
        events: {
            handleSubmitForm,
            handleRedirect
        },
    } = useRegistrationPage();

    return (
        <Box
            display="flex"
            flexDirection="column"
            gap={2}
            component="form"
            onSubmit={handleSubmitForm}
        >
            <StyledDescriptionText textAlign="center" mb={2}>
                {userProfile.email}
            </StyledDescriptionText>
            <TextField
                type={passwordVisibility.visibility ? 'text' : 'password'}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={passwordVisibility.setVisibility} edge="end">
                                {passwordVisibility.visibility ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                {...register('newPassword', {
                    label: `${t("registerPage.password")}*`,
                    pattern: ({ newPassword, confirmPassword }) => {
                        return {
                            newPassword: !newPassword && t("validations.newPassword"),
                            confirmPassword: !(newPassword === confirmPassword) && t("validations.confirmPassword")
                        }
                    }
                })}
            />
            <TextField
                type={confirmPasswordVisibility.visibility ? 'text' : 'password'}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={confirmPasswordVisibility.setVisibility} edge="end">
                                {confirmPasswordVisibility.visibility ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                {...register('confirmPassword', {
                    label: `${t("registerPage.confirmPassword")}*`,
                    pattern: ({ newPassword, confirmPassword }) => {
                        return {
                            confirmPassword: !(newPassword && newPassword === confirmPassword) && t("validations.confirmPassword")
                        }
                    }
                })}
            />
            <Button type="submit" fullWidth>
                {t('shared.send')}
            </Button>
            <Link onClick={handleRedirect}>
                {t("registerPage.goBack")}
            </Link>
        </Box>
    );
};
