import { Box, Icon, IconButton, Tooltip as MuiTooltip, Stack, styled, useMediaQuery, useTheme } from '@mui/material';
import { ChartData } from 'chart.js';
import moment from "moment";
import { default as React } from 'react';
import { Line } from "react-chartjs-2";
import { useTranslation } from 'react-i18next';
import { FaTrash } from "react-icons/fa";
import { FaArrowTrendDown, FaArrowTrendUp, FaPeopleGroup, FaSackDollar } from "react-icons/fa6";
import { TbListDetails } from 'react-icons/tb';
import { useMathUtilities } from '../../hooks/useMathUtilities';
import { FundWithOperationApiDto } from '../../types/fund.types';
import { CFFDateFormat } from '../../../constants/appRegex';

const StyledContainer = styled(Box)<{ status: string }>(({ status }) => ({
    boxSizing: 'border-box',
    background: `radial-gradient(#fff 50%, var(--fund-status-${status}))`,
    border: `2px solid var(--fund-status-${status})`,
    padding: '16px 16px 0',
    color: '#000',
    borderRadius: '4px',
    gap: 1,
    minHeight: '260px',
    display: 'flex',
    flexDirection: 'column',
}));

const StyledBoxWithNumber = styled(Box)(({ color }) => ({
    display: 'flex',
    fontWeight: '400',
    alignItems: 'center',
    justifyContent: 'left',
    fontSize: '0.8rem',
    gap: 1
}));

export interface FundCardProps {
    fundWithOperations: FundWithOperationApiDto;
    events?: {
        handleDelete?: (fundWithOperations: FundWithOperationApiDto) => void;
        handleDetailsClick?: (fundWithOperations: FundWithOperationApiDto) => void;
        handleDeleteClick?: (fundWithOperations: FundWithOperationApiDto) => void;
    }
}

const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false,
            position: 'top' as const,
        },
        title: {
            display: false,
            text: 'Balance/Equity',
        },
    },
    scales: {
        x: {
            display: false,
        },
        y: {
            display: false,
        }
    }
};

export const useFundCard = (props: FundCardProps) => {

    const { fundWithOperations } = props;
    const { t } = useTranslation();

    let statistics = React.useMemo(() => {

        const operationsCount = fundWithOperations?.operations?.length;

        if (operationsCount) {

            const chartData: ChartData<'line'> = {
                labels: [],
                datasets: [
                    {
                        borderDash: [4, 4],
                        fill: false,
                        label: t("fundDetailsPage.expectedProfit"),
                        borderColor: 'rgba(116, 116, 255)',
                        pointRadius: 0,
                        borderWidth: 1,
                        data: [],
                    },
                    {
                        fill: true,
                        borderColor: fundWithOperations?.statistics?.variation > 0 ? 'rgb(50, 116, 50)' : 'rgba(255, 116, 116)',
                        backgroundColor: fundWithOperations?.statistics?.variation > 0 ? 'rgb(50, 116, 50, 0.1)' : 'rgba(255, 116, 116, 0.1)',
                        pointRadius: 0,
                        borderWidth: 1,
                        data: [],
                    },
                    {
                        fill: false,
                        borderColor: 'rgb(255, 165, 0)',
                        pointRadius: 0,
                        borderWidth: 1,
                        data: [],
                    }
                ]
            };

            const diff = Math.round(moment(fundWithOperations.endDate).diff(fundWithOperations.startDate) / 1000 / 60 / 60 / 24);
            const expectedProfit = ((Math.round((fundWithOperations.balance / 100) * fundWithOperations.expectedProfit)) / diff);
            const lastBalance = fundWithOperations?.operations[operationsCount - 1]?.balance;

            fundWithOperations.operations.map((operation, i) => {
                chartData?.labels?.push('');
                chartData.datasets[0].data.push(fundWithOperations.balance + (expectedProfit * i));
                chartData.datasets[1].data.push(operation.balance)
                chartData.datasets[2].data.push(operation.equity)
            });

            return {
                chartData,
                lastBalance
            };
        }

        return null;
    }, []);

    return {
        models: {
            fundWithOperations,
            statistics
        }
    }
}

export const FundCard = (props: FundCardProps) => {

    const { events } = props;

    const theme = useTheme();
    const mdNoMatches = useMediaQuery(theme.breakpoints.up('md'));
    const { t } = useTranslation();

    const mathUtilities = useMathUtilities();

    const {
        models: {
            fundWithOperations,
            statistics
        }
    } = useFundCard(props);

    return (
        <StyledContainer status={fundWithOperations.status}>
            <Box display="flex" flexDirection="column" justifyContent="center" gap={1}>
                <Box display="flex" alignItems="center" gap={1}>
                    {fundWithOperations.logo ?
                        <img src={fundWithOperations.logo} width="35px" height="35px" style={{ borderRadius: '50%' }} alt="logo" /> :
                        <Icon fontSize="medium">
                            <FaSackDollar />
                        </Icon>
                    }
                    <Box display="flex" flexDirection="column" fontSize="1rem" flexGrow={1}>
                        <Box display="flex" alignItems="center" justifyContent="space-between" fontWeight={500} >
                            <Box flexGrow={1} sx={{ wordBreak: 'break-all' }}>
                                {fundWithOperations.name}
                            </Box>
                            {statistics?.lastBalance && (
                                <Box sx={{ wordBreak: 'break-all' }}>
                                    {mathUtilities.toLocalePrice(statistics?.lastBalance)}€
                                    ({mathUtilities.toLocalePrice(fundWithOperations?.statistics?.variationPercentage)}%)
                                </Box>
                            )}
                        </Box>
                        <Box display="flex" alignItems="center" gap={1}>
                            {fundWithOperations.public && (
                                <MuiTooltip title={t("shared.public")}>
                                    <Icon fontSize="small" sx={{ fontSize: '0.8rem' }}>
                                        <FaPeopleGroup />
                                    </Icon>
                                </MuiTooltip>
                            )}
                            {!!statistics?.lastBalance && (
                                <MuiTooltip title={(statistics?.lastBalance > +fundWithOperations.balance) ? t("shared.profit") : t("shared.loss")}>
                                    <Icon fontSize="small" sx={{ fontSize: '0.8rem' }}>
                                        {(statistics?.lastBalance > +fundWithOperations.balance) ?
                                            <FaArrowTrendUp style={{ color: 'var(--success)' }} /> :
                                            <FaArrowTrendDown style={{ color: 'var(--error)' }} />}
                                    </Icon>
                                </MuiTooltip>
                            )}
                        </Box>
                    </Box>
                </Box>
                <Box>
                    {fundWithOperations.balance && (
                        <StyledBoxWithNumber>
                            <b>{t("fundsPage.initialBalance")}: </b> {mathUtilities.toLocalePrice(fundWithOperations.balance)}€
                        </StyledBoxWithNumber>
                    )}
                    {statistics?.lastBalance && (
                        <StyledBoxWithNumber>
                            <b>{t("fundsPage.drawdown")}: </b>
                            {mathUtilities.toLocalePrice(fundWithOperations?.statistics?.drawdown)}€
                            ({mathUtilities.toLocalePrice(fundWithOperations?.statistics?.drawdownPercentage)}%)
                        </StyledBoxWithNumber>
                    )}
                </Box>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center" my={1} flexGrow={1}>
                {statistics?.chartData.datasets.length ? (
                    <Line
                        style={{ width: '100%', height: '90px' }}
                        options={chartOptions}
                        data={statistics.chartData}
                    />
                ) : (
                    <Box color="var(--description-text)">
                        {t("fundsPage.noOperations")}
                    </Box>
                )}
            </Box>
            <Stack direction="row" width="100%" justifyContent="end" alignItems="center" spacing={1} mb={1}>
                <em style={{ fontWeight: '400', fontSize: '0.8rem', flexGrow: 1 }}>
                    {t("fundsPage.validityCardText", {
                        startDate: moment(fundWithOperations.startDate).format(CFFDateFormat.short),
                        endDate: moment(fundWithOperations.endDate).format(CFFDateFormat.short),
                    })}
                </em>
                {mdNoMatches && events?.handleDetailsClick && (
                    <MuiTooltip title={t("shared.details")}>
                        <IconButton
                            size="small"
                            onClick={() => {
                                if (events?.handleDetailsClick)
                                    events.handleDetailsClick(fundWithOperations)
                            }}
                        >
                            <TbListDetails />
                        </IconButton>
                    </MuiTooltip>
                )}
                {mdNoMatches && events?.handleDeleteClick && (
                    <MuiTooltip title={t("shared.delete")}>
                        <IconButton
                            size="small"
                            onClick={() => {
                                if (events?.handleDeleteClick)
                                    events.handleDeleteClick(fundWithOperations)
                            }}
                        >
                            <FaTrash style={{ color: 'var(--error)' }} />
                        </IconButton>
                    </MuiTooltip>
                )}
            </Stack>
        </StyledContainer>
    );
}
