import { Refresh } from '@mui/icons-material';
import { Box, Grid, Icon, Tooltip } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MdErrorOutline } from 'react-icons/md';
import { CFFDateFormat } from '../../../constants/appRegex';
import { RT, TFTable } from '../../../shared/components';
import { useNotifyContext, useProfileContext } from '../../../shared/contexts';
import { GetWalletOperations } from '../../../shared/helpers/wallets.services';
import { useErrorTranslation } from '../../../shared/hooks';
import { useMathUtilities } from '../../../shared/hooks/useMathUtilities';
import { DetailsPanelProps, WalletOperation } from '../types/WalletsPage.types';

export const OperationsPanel: React.FC<DetailsPanelProps> = (props) => {

    const { t } = useTranslation();

    const notify = useNotifyContext();
    const { profile } = useProfileContext();
    const { toLocalePrice } = useMathUtilities();
    const getErrorTranslation = useErrorTranslation();
    const tableRef = React.useRef() as React.MutableRefObject<RT<WalletOperation>>;

    return (
        <Box px={2} sx={{ width: 'calc(100vw - 282px)' }}>
            <Grid spacing={2} container>
                <Grid xs={12} sm={12} md={12} item>
                    <TFTable<WalletOperation>
                        ref={tableRef}
                        initialize={{
                            sorting: {
                                field: "CreationDate",
                                order: "Desc"
                            }
                        }}
                        data={(query) => {

                            return new Promise((result) => {

                                GetWalletOperations({
                                    params: {
                                        orderBy: query?.sorting?.field,
                                        orderByDescending: query?.sorting?.field === 'Desc',
                                        code: props?.wallet?.code,
                                        userOid: profile?.id || '',
                                        pageNumber: query?.pageNumber,
                                        pageSize: query?.pageSize,
                                    }
                                }).then(({ data }) => {

                                    result({
                                        data: data.walletOperations.sort((a, b) => b.creationDate.localeCompare(a.creationDate)),
                                        totalCount: data.totalCount
                                    });
                                }).catch((ev) => {

                                    const status = ev.response?.data?.errorCode || ev.response?.status || 500;
                                    const url = ev.config.url;
                                    notify.warning({
                                        content: getErrorTranslation(url, status)
                                    });
                                });
                            })
                        }}
                        actions={[
                            {
                                icon: <Refresh />,
                                tooltip: t("shared.table.TT_refresh"),
                                onClick: () => {
                                    tableRef.current.reload();
                                }
                            }
                        ]}
                        options={{
                            disableRow: (_data, { row }) => row !== 0,
                            rowStyle: (_data, { row }) => {
                                if (row !== 0) {
                                    return { opacity: 0.8 };
                                } else {
                                    return {};
                                };
                            },
                            usePagination: true,
                            useSearch: false,
                            localize: {
                                noData: t('shared.table.gridEmptyMsg'),
                                resetFilters: t('shared.table.resetFilters'),
                                search: t('shared.table.search'),
                                displayedElementsSeparator: t('shared.table.displayedElementsSeparator'),
                                rowsForPage: t('shared.table.rowsForPage'),
                            },
                            minWidth: "1000px",
                            style: {
                                maxHeight: "calc(100vh - 242px)"
                            },
                            filtersAdjustmentStyle: {
                                top: '40px'
                            }
                        }}
                        columns={[
                            {
                                title: () => t("walletDetailsPage.description"),
                                render: ({ description }, _filters, optionals) => {
                                    return (
                                        <Box display="flex" alignItems="center" gap={1}>
                                            {optionals?.row !== 0 && (
                                                <Tooltip title={t("shared.warningRow")}>
                                                    <Icon fontSize="small" sx={{  marginBottom: '4px' }}>
                                                        <MdErrorOutline color="var(--warning)" />
                                                    </Icon>
                                                </Tooltip>
                                            )}
                                            {description}
                                        </Box>
                                    );
                                },
                            },
                            {
                                title: () => t("walletDetailsPage.creationDate"),
                                render: ({ creationDate }) => moment(creationDate).format(CFFDateFormat.long),
                                thStyle: { width: 150, textAlign: 'right', },
                                style: { width: 150, textAlign: 'right', },
                            },
                            {
                                title: () => t("walletDetailsPage.amount"),
                                render: ({ amount }) => toLocalePrice(amount),
                                thStyle: { width: 150, textAlign: 'right', },
                                style: { width: 150, textAlign: 'right', },
                            },
                            {
                                title: () => t("walletDetailsPage.progressive"),
                                render: ({ progressive }) => toLocalePrice(progressive),
                                thStyle: { width: 150, textAlign: 'right', },
                                style: { width: 150, textAlign: 'right', },
                            }
                        ]}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}
