import axios from 'axios';
import {
    AddWalletOperationsRequest,
    AddWalletOperationsResponse,
    AddWalletRequest,
    AddWalletResponse,
    DeleteWalletOperationRequest,
    GetWalletOperationsRequest,
    GetWalletOperationsResponse,
    GetWalletRequest,
    GetWalletResponse,
    GetWalletsRequest,
    GetWalletsResponse,
    UpdateWalletOperationsRequest,
    UpdateWalletRequest,
} from '../types/wallets.types';

const baseUrl = '/api/wallets';

export const GetWallets =
    async (request: GetWalletsRequest) => await axios.get<GetWalletsResponse>(`${baseUrl}`, request);

export const GetWallet =
    async (request: GetWalletRequest) => await axios.get<GetWalletResponse>(`${baseUrl}/${request.params.code}`, { params: request.params });

export const AddWallet =
    async (request: AddWalletRequest) => await axios.post<AddWalletResponse>(`${baseUrl}`, request.data);

export const UpdateWallet =
    async (request: UpdateWalletRequest) => await axios.put(`${baseUrl}/${request.params.code}`, request.data);

export const GetWalletOperations =
    async (request: GetWalletOperationsRequest) => await axios.get<GetWalletOperationsResponse>(`${baseUrl}/${request.params.code}/operations`, { params: request.params });

export const AddWalletOperations =
    async (request: AddWalletOperationsRequest) => await axios.post<AddWalletOperationsResponse>(`${baseUrl}/${request.params.walletOid}/operations`, request.data);

export const UpdateWalletOperations =
    async (request: UpdateWalletOperationsRequest) => await axios.put(`${baseUrl}/${request.params.walletOid }/operations/${request.params.operationOid}`, request.data);

export const DeleteWalletOperation =
    async (request: DeleteWalletOperationRequest) => await axios.delete(`${baseUrl}/${request.params.walletOid}/operations/${request.params.operationOid}`);
