export const routes = {
    loginRoute: '/',
    wallets: 'wallets',
    adminWalletsView: 'adminWalletsView',
    registration: 'registration',
    forgotPassword: 'forgotPassword',
    protected: 'protected',
    errors: 'errors',
    funds: 'funds',
    fundDetails: 'details',
    walletDetails: 'details',
    user: 'user',
    users: 'users',
    invitations: 'invitations',
    confirmEmail: 'confirm/email',
    confirmForgotPassword: 'confirm/resetPassword',
};
