import { createTheme } from '@mui/material';
import { MuiBreadcrumbs } from './MuiBreadcrumbs';
import { MuiButton } from './MuiButton';
import { MuiCheckbox } from './MuiCheckbox';
import { MuiChip } from './MuiChip';
import { MuiIconButton } from './MuiIconButton';
import { MuiLink } from './MuiLink';
import { MuiListItemButton } from './MuiListItemButton';
import { MuiStack } from './MuiStack';
import { MuiSwitch } from './MuiSwitch';
import { MuiTab } from './MuiTab';
import { MuiTabs } from './MuiTabs';
import { MuiTextField } from './MuiTextField';
import { MuiToolbar } from './MuiToolbar';
import { MuiTooltip } from './MuiTooltip';
import { MuiSelect } from './MuiSelect';
import { MuiPaper } from './MuiPaper';

export const appMuiTheme = createTheme({
    palette: {
        primary: {
            main: '#1c6e3d',
        },
        secondary: {
            main: '#a53636',
        },
        error: {
            main: 'rgb(115, 37, 37)',
        },
    },
    components: {
        MuiPaper,
        MuiSelect,
        MuiTabs,
        MuiCheckbox,
        MuiTab,
        MuiSwitch,
        MuiLink,
        MuiButton,
        MuiTextField,
        MuiTooltip,
        MuiIconButton,
        MuiToolbar,
        MuiBreadcrumbs,
        MuiListItemButton,
        MuiStack,
        MuiChip
    }
});
