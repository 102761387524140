import { Add, Refresh } from '@mui/icons-material';
import { Box, Button, Grid, IconButton, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';
import { PageLoader } from '../../../shared/components/Common/PageLoader';
import { PageTitle } from '../../../shared/components/Common/PageTitle';
import { StyledPageContainer } from '../../../shared/components/Common/StyledPageContainer';
import { WalletCard } from '../../../shared/components/Common/WalletCard';
import { useLoaderContext, useProfileContext } from '../../../shared/contexts';
import { GetWallet, GetWallets } from '../../../shared/helpers/wallets.services';
import { useStandardCatch } from '../../../shared/hooks/useStandardCatch';
import { WalletApiDto } from '../../../shared/types/wallets.types';
import { Wallet } from '../types/WalletsPage.types';
import { EmptyWalletsPage } from '../../../shared/components/Common/EmptyWalletPage';

const useWalletsPage = () => {

    const { setLoader } = useLoaderContext();
    const navigate = useNavigate();
    const profileContext = useProfileContext();

    const { baseCatch } = useStandardCatch();
    const [pageLoader, setPageLoader] = React.useState(true);
    const [wallets, setWallets] = React.useState<WalletApiDto[]>([]);

    React.useEffect(() => {
        handleGetWalletReload();
    }, []);

    const handleGetWalletReload = () => {

        setPageLoader(true);
        GetWallets({
            params: {
                userId: profileContext.profile.id
            }
        })
            .then(({ data }) => {
                setPageLoader(false);
                setWallets(data.wallets);
            })
            .catch((ev) => {
                setPageLoader(false);
                baseCatch(ev);
            });
    }

    const handleWalletDetailsRedirect = (wallet: Wallet, tab = 0) => {

        setLoader(true);

        GetWallet({
            params: {
                code: wallet.code,
                userId: profileContext.profile.id
            }
        })
            .then(({ data }) => {
                setLoader(false);
                navigate('/protected/wallets/details', { state: { wallet: data.wallet, tab } });
            })
            .catch(ev => {
                setLoader(false);
                baseCatch(ev);
            });
    };

    const handleAddWallet = () => {
        navigate('/protected/wallets/details');
    };

    return {
        models: {
            profileContext,
            wallets,
            loader: pageLoader
        },
        events: {
            handleRefresh: handleGetWalletReload,
            handleWalletDetailsRedirect,
            handleAddWallet
        }
    }
}

export const WalletsPage = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const theme = useTheme();
    const smNoMatches = useMediaQuery(theme.breakpoints.up('md'));


    const handleRedirect = () => {
        navigate("/protected/users");
    }

    const {
        models: {
            profileContext,
            wallets,
            loader
        },
        events: {
            handleRefresh,
            handleWalletDetailsRedirect,
            handleAddWallet
        }
    } = useWalletsPage();

    return (
        <StyledPageContainer>
            <PageTitle title={t("shared.sectionWallets")} />
            {smNoMatches && (
                <Box display="flex" alignItems="center" gap={2} p={2}>
                    <Box display="flex" alignItems="center" flexWrap="wrap" gap={2} flexGrow={1}>
                        <Tooltip title={t("walletsPage.TT_refreshAction")}>
                            <Box>
                                <IconButton size="small" disabled={loader} onClick={handleRefresh} sx={{ p: 0 }}>
                                    <Refresh />
                                </IconButton>
                            </Box>
                        </Tooltip>
                    </Box>
                    <Button onClick={handleAddWallet} startIcon={<Add />}>
                        {t('walletsPage.addWallet')}
                    </Button>
                </Box>
            )}
            <Box overflow="auto" height={smNoMatches ? "calc(100vh - 155px)" : "auto"} px={2}>
                <Grid container spacing={2}>
                    {loader ? (
                        <Grid sm={12} item overflow="hidden">
                            <PageLoader />
                        </Grid>
                    ) : (
                        wallets.length ? (
                            wallets.map((wallet, i) => (
                                <Grid xs={12} sm={12} md={12} lg={4} xl={4} item key={`fundcard-${i}`}>
                                    <WalletCard
                                        wallet={wallet}
                                        events={{
                                            handleDetailsClick: () => {
                                                handleWalletDetailsRedirect(wallet);
                                            },
                                        }}
                                    />
                                </Grid>
                            ))
                        ) : (
                            <Grid sm={12} item sx={{ height: '100%' }}>
                                <EmptyWalletsPage />
                            </Grid>
                        )
                    )}
                </Grid>
            </Box>
        </StyledPageContainer>
    );
};
