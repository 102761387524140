import { Add, Refresh } from '@mui/icons-material';
import { Box, Breadcrumbs, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Icon, Link, TextField, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BiTrash } from 'react-icons/bi';
import { MdErrorOutline } from 'react-icons/md';
import { Location, useLocation, useNavigate } from "react-router-dom";

import { CFFDateFormat } from '../../../constants/appRegex';
import { routes } from "../../../constants/appRoutes";
import { RT, TFTable } from '../../../shared/components';
import { PageTitle } from '../../../shared/components/Common/PageTitle';
import { StyledPageContainer } from '../../../shared/components/Common/StyledPageContainer';
import { useDialogContext, useLoaderContext, useNotifyContext } from '../../../shared/contexts';
import { AddWalletOperations, DeleteWalletOperation, GetWalletOperations, UpdateWalletOperations } from '../../../shared/helpers/wallets.services';
import { useErrorTranslation } from '../../../shared/hooks';
import { useMathUtilities } from '../../../shared/hooks/useMathUtilities';
import { useStandardCatch } from '../../../shared/hooks/useStandardCatch';
import { Wallet, WalletDetailsTabValue, WalletLocationState, WalletOperation } from "../types/WalletsPage.types";
import { useForm } from '../../../shared/hooks/useForm';

export const WalletDetailsTabs: Record<'Details' | 'Transactions', WalletDetailsTabValue> = {
    Details: 0,
    Transactions: 1,
}

export const WalletDetailsPage = () => {

    const navigate = useNavigate();
    const location: Location<WalletLocationState> = useLocation();

    const wallet = location.state.wallet as Wallet;

    const { t } = useTranslation();

    const notify = useNotifyContext();
    const { toLocalePrice } = useMathUtilities();
    const { baseCatch } = useStandardCatch();
    const { setLoader } = useLoaderContext();
    const getErrorTranslation = useErrorTranslation();
    const tableRef = React.useRef() as React.MutableRefObject<RT<WalletOperation>>;
    const setDialog = useDialogContext();

    const {
        register,
        clean,
        submit,
        state,
        setState
    } = useForm({
        oid: '',
        description: '',
        amount: 0
    });

    const [walletDialog, setWalletDialog] = React.useState({
        open: false,
    });

    const toggleInvitationDialogState = () => {
        const open = !walletDialog.open;
        setWalletDialog({
            ...walletDialog,
            open
        });
    }
    const handleInvitationSubmit = submit((state) => {

        setLoader(true);
        if (state.oid) {

            UpdateWalletOperations({
                params: {
                    walletOid: wallet.code,
                    operationOid: state.oid,
                },
                data: {
                    description: state.description,
                    amount: state.amount
                }
            })
                .then(() => {
                    notify.success({
                        content: t("success:updateWalletOperations")
                    });
                    tableRef.current.reload();
                    toggleInvitationDialogState();
                    setLoader(false);
                })
                .catch(ev => {
                    setLoader(false);
                    baseCatch(ev, '/api/wallets/operations');
                });
        } else {

            AddWalletOperations({
                params: {
                    walletOid: wallet.code,
                },
                data: {
                    description: state.description,
                    amount: state.amount
                }
            })
                .then(() => {
                    notify.success({
                        content: t("success:addWalletOperations")
                    });
                    tableRef.current.reload();
                    toggleInvitationDialogState();
                    setLoader(false);
                })
                .catch(ev => {
                    setLoader(false);
                    baseCatch(ev, '/api/wallets/operations');
                });
        }
    });

    const handleRedirect = () => {
        navigate(`/protected/users`, location);
    }

    const handleRedirectToWallets = () => {
        navigate(`/protected/users/${routes.adminWalletsView}`, location);
    }

    return (
        <StyledPageContainer>
            <PageTitle
                title={t("walletsPage.pageTitle")}
                breadcrumbs={(
                    <Breadcrumbs>
                        <Link onClick={handleRedirect} sx={{ fontSize: '0.8rem' }} >
                            {location.state.email}
                        </Link>
                        <Link onClick={handleRedirectToWallets} sx={{ fontSize: '0.8rem' }} >
                            {t("shared.sectionWallets")}
                        </Link>
                        <Typography sx={{ fontSize: '0.8rem', color: '#1c6e3d', fontWeight: '500', wordBreak: 'break-all' }}>
                            [{wallet.code}] {wallet.name}
                        </Typography>
                    </Breadcrumbs>
                )}
            />
            <Box px={2} sx={{ width: 'calc(100vw - 282px)', mt: 1 }}>
                <Grid spacing={2} container>
                    <Grid xs={12} sm={12} md={12} item>
                        <TFTable<WalletOperation>
                            ref={tableRef}
                            initialize={{
                                sorting: {
                                    field: "CreationDate",
                                    order: "Desc"
                                }
                            }}
                            data={(query) => {

                                return new Promise((result) => {

                                    GetWalletOperations({
                                        params: {
                                            orderBy: query?.sorting?.field,
                                            orderByDescending: query?.sorting?.field === 'Desc',
                                            code: wallet?.code,
                                            userOid: '' + location.state.userId,
                                            pageNumber: query?.pageNumber,
                                            pageSize: query?.pageSize,
                                        }
                                    }).then(({ data }) => {

                                        result({
                                            data: data.walletOperations.sort((a, b) => b.creationDate.localeCompare(a.creationDate)),
                                            totalCount: data.totalCount
                                        });
                                    }).catch((ev) => {

                                        const status = ev.response?.data?.errorCode || ev.response?.status || 500;
                                        const url = ev.config.url;
                                        notify.warning({
                                            content: getErrorTranslation(url, status)
                                        });
                                    });
                                })
                            }}
                            events={{
                                onRowClick: (data) => {

                                    clean();
                                    setState(data);
                                    setWalletDialog({
                                        open: true,
                                    });
                                }
                            }}
                            actions={[
                                {
                                    icon: <Refresh />,
                                    tooltip: t("shared.table.TT_refresh"),
                                    onClick: () => {
                                        tableRef.current.reload();
                                    }
                                },
                                {
                                    icon: <Add />,
                                    tooltip: t("shared.table.add_Title"),
                                    onClick: () => {
                                        clean();
                                        setWalletDialog({
                                            open: true,
                                        });
                                    }
                                }
                            ]}
                            options={{
                                disableRow: (_data, { row }) => row !== 0,
                                rowStyle: (_data, { row }) => {
                                    if (row !== 0) {
                                        return { opacity: 0.8 };
                                    } else {
                                        return {};
                                    };
                                },
                                usePagination: true,
                                useSearch: false,
                                localize: {
                                    noData: t('shared.table.gridEmptyMsg'),
                                    resetFilters: t('shared.table.resetFilters'),
                                    search: t('shared.table.search'),
                                    displayedElementsSeparator: t('shared.table.displayedElementsSeparator'),
                                    rowsForPage: t('shared.table.rowsForPage'),
                                },
                                minWidth: "1000px",
                                style: {
                                    maxHeight: "calc(100vh - 242px)"
                                },
                                filtersAdjustmentStyle: {
                                    top: '40px'
                                }
                            }}
                            columns={[
                                {
                                    title: () => t("walletDetailsPage.description"),
                                    render: ({ description }, _filters, optionals) => {
                                        return (
                                            <Box display="flex" alignItems="center" gap={1}>
                                                {optionals?.row !== 0 && (
                                                    <Tooltip title={t("shared.warningRow")}>
                                                        <Icon fontSize="small" sx={{ marginBottom: '4px' }}>
                                                            <MdErrorOutline color="var(--warning)" />
                                                        </Icon>
                                                    </Tooltip>
                                                )}
                                                {description}
                                            </Box>
                                        );
                                    },
                                },
                                {
                                    title: () => t("walletDetailsPage.creationDate"),
                                    render: ({ creationDate }) => moment(creationDate).format(CFFDateFormat.long),
                                    thStyle: { width: 150, textAlign: 'right', },
                                    style: { width: 150, textAlign: 'right', },
                                },
                                {
                                    title: () => t("walletDetailsPage.amount"),
                                    render: ({ amount }) => toLocalePrice(amount),
                                    thStyle: { width: 150, textAlign: 'right', },
                                    style: { width: 150, textAlign: 'right', },
                                },
                                {
                                    title: () => t("walletDetailsPage.progressive"),
                                    render: ({ progressive }) => toLocalePrice(progressive),
                                    thStyle: { width: 150, textAlign: 'right', },
                                    style: { width: 150, textAlign: 'right', },
                                },
                                {
                                    tooltip: t("shared.delete"),
                                    hidden: (_data, { row }) => row !== 0,
                                    icon: <BiTrash style={{ fill: '#D82829' }} />,
                                    style: { padding: '0 5px', width: 40, textAlign: "center", svg: { color: '#D82829', fill: '#D82829' } },
                                    thStyle: { padding: '0 5px', width: 40, textAlign: "center", svg: { color: '#D82829', fill: '#D82829' } },
                                    onClick: (data) => {

                                        setDialog({
                                            open: true,
                                            title: `${t('shared.dialogRemove_title')}`,
                                            content: t('walletDetailsPage.dialog_RemoveOperation_body'),
                                            yesCallback: (closeDialog) => {

                                                setLoader(true);
                                                DeleteWalletOperation({
                                                    params: {
                                                        operationOid: data.oid,
                                                        walletOid: wallet.code
                                                    }
                                                }).then(({ data }) => {

                                                    setLoader(false);
                                                    tableRef.current.reload();
                                                    notify.success({
                                                        content: t("success:deleteWalletOperation")
                                                    });
                                                    closeDialog();
                                                }).catch((ev) => {

                                                    setLoader(false);
                                                    const url = '/api/wallets';
                                                    baseCatch(ev, url);
                                                });
                                            },
                                            noCallback: (closeDialog) => {

                                                closeDialog();
                                            }
                                        });
                                    },
                                }
                            ]}
                        />
                    </Grid>
                </Grid>
                <Dialog
                    sx={{
                        '& .MuiDialogTitle-root': {
                            color: '#1c6e3d',
                            borderBottom: '2px solid #1c6e3d',
                            backgroundColor: 'white'
                        },
                    }}
                    open={walletDialog.open}
                    fullWidth
                    maxWidth="xs"
                >
                    <DialogTitle>{state.oid ? t("walletDetailsPage.dialog_UpdateWalletOperation_Title") : t("walletDetailsPage.dialog_AddWalletOperation_Title")}</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2} pt={2}>
                            <Grid xs={12} item>
                                <TextField
                                    rows={3}
                                    multiline
                                    {...register('description', {
                                        label: `${t("walletDetailsPage.description")}*`,
                                        pattern: (state) => {
                                            return {
                                                description: !state.description ? t("validations.invalidDescription") : false
                                            };
                                        }
                                    })}
                                />
                            </Grid>
                            <Grid xs={12} item>
                                <TextField
                                    {...register('amount', {
                                        label: `${t("walletDetailsPage.amount")}*`,
                                        pattern: (state) => {
                                            return {
                                                amount: state.amount <= 0 ? t("validations.invalidNumber") : false
                                            };
                                        }
                                    })}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions sx={{ backgroundColor: '#eee' }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                                toggleInvitationDialogState();
                            }}
                        >
                            {t("shared.close")}
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                handleInvitationSubmit();
                            }}
                        >
                            {t("shared.save")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </StyledPageContainer>
    );
};
