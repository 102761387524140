import { Euro, ExitToApp, Mail, Person } from '@mui/icons-material';
import { Box, IconButton, List, ListItem, ListItemButton, Tooltip } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { MdManageAccounts } from 'react-icons/md';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { FaWallet } from 'react-icons/fa';

import { routes } from '../../../constants/appRoutes';
import { ApplicationLogo } from '../../components/Common/ApplicationLogo';
import { CFFDrawer, StyledCFFDrawerListItemIcon, StyledCFFDrawerListItemText } from '../../components/Common/Drawer';
import { useProfileContext } from '../../contexts';
import { IsAuthenticated } from '../../helpers/auth.services';

export const useProtectedOutlet = () => {

    const navigate = useNavigate();

    const profileContext = useProfileContext();

    React.useEffect(() => {

        IsAuthenticated()
            .then(({ data }) => {
                profileContext.setProfile(data);
            })
            .catch((err) => {
                const code = err?.response?.status || 500;
                navigate(`/${routes.errors}`, { state: { code } });
            });
    }, []);

    return {
        helpers: {
            profileContext
        },
    }
}

export const ProtectedOutlet = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();

    const {
        helpers: {
            profileContext
        },
    } = useProtectedOutlet();

    return (
        <Box display="flex" height="100%">
            <CFFDrawer variant="permanent">
                <Box display="flex" flexDirection="column">
                    <ApplicationLogo />
                    <Box
                        display="flex"
                        flexDirection="column"
                        gap={1}
                        p={1}
                        m={1}
                        borderRadius={1}
                        border="1px solid var(--primary-wo)"
                        color="var(--primary)"
                        sx={{ background: 'var(--primary-light-wo)' }}
                    >
                        <Box
                            alignItems="center"
                            display="flex"
                            gap={1 / 2}
                            className="ellipsis-text"
                            fontWeight="bold"
                            fontSize="1rem"
                        >
                            <Tooltip title={t("shared.sectionUsers")}>
                                <IconButton
                                    onClick={() => navigate(routes.user)}
                                    size="small"
                                    sx={{ p: 0, color: 'var(--primary)' }}
                                >
                                    <MdManageAccounts style={{ fontSize: '1.2rem' }} />
                                </IconButton>
                            </Tooltip>
                            {profileContext.profile.email?.split("@")[0]}
                        </Box>
                        <Box display="flex" gap="4px" flexWrap="wrap">
                            {profileContext.profile.roles?.map((role, key) => (
                                <Box
                                    key={`role-box-${key}`}
                                    display="flex"
                                    alignItems="center"
                                    sx={{
                                        backgroundColor: 'var(--role-' + role + '-color-wo)',
                                        borderRadius: '4px',
                                        p: '2px 4px',
                                        border: '1px dashed var(--role-' + role + '-color)',
                                        color: 'var(--role-' + role + '-color)',
                                        fontSize: '0.6rem',
                                        fontWeight: 'bold',
                                        textTransform: 'uppercase'
                                    }}
                                >
                                    {role}
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Box>
                <Box height="100%">
                    <List disablePadding dense>
                        <ListItem disablePadding>
                            <ListItemButton
                                dense
                                onClick={() => navigate(routes.funds)}
                                selected={location.pathname.includes(routes.funds) || location.pathname === '/protected'}
                            >
                                <StyledCFFDrawerListItemIcon>
                                    <Euro style={{ fontSize: '1.2rem' }} />
                                </StyledCFFDrawerListItemIcon>
                                <StyledCFFDrawerListItemText>
                                    {t("shared.sectionFunds")}
                                </StyledCFFDrawerListItemText>
                            </ListItemButton>
                        </ListItem>
                        {profileContext.isAdmin && (
                            <ListItem disablePadding>
                                <ListItemButton
                                    dense
                                    onClick={() => navigate(routes.invitations)}
                                    selected={location.pathname.includes(routes.invitations)}
                                >
                                    <StyledCFFDrawerListItemIcon>
                                        <Mail style={{ fontSize: '1.2rem' }} />
                                    </StyledCFFDrawerListItemIcon>
                                    <StyledCFFDrawerListItemText>
                                        {t("shared.sectionInvitations")}
                                    </StyledCFFDrawerListItemText>
                                </ListItemButton>
                            </ListItem>
                        )}
                        {profileContext.isAdmin && (
                            <ListItem disablePadding>
                                <ListItemButton
                                    dense
                                    onClick={() => navigate(routes.users)}
                                    selected={location.pathname === `/protected/${routes.users}` || location.pathname.includes(`/protected/${routes.users}/${routes.adminWalletsView}`)}
                                >
                                    <StyledCFFDrawerListItemIcon>
                                        <Person style={{ fontSize: '1.2rem' }} />
                                    </StyledCFFDrawerListItemIcon>
                                    <StyledCFFDrawerListItemText>
                                        {t("shared.sectionPlatformUsers")}
                                    </StyledCFFDrawerListItemText>
                                </ListItemButton>
                            </ListItem>
                        )}
                        {(profileContext.isInvestor || profileContext.isGuest) && (
                            <ListItem disablePadding>
                                <ListItemButton
                                    dense
                                    onClick={() => navigate(routes.wallets)}
                                    selected={location.pathname.includes(`/protected/${routes.wallets}`)}
                                >
                                    <StyledCFFDrawerListItemIcon>
                                        <FaWallet style={{ fontSize: '1.2rem' }} />
                                    </StyledCFFDrawerListItemIcon>
                                    <StyledCFFDrawerListItemText>
                                        {t("shared.sectionWallets")}
                                    </StyledCFFDrawerListItemText>
                                </ListItemButton>
                            </ListItem>
                        )}
                    </List>
                </Box>
                <List dense>
                    <List disablePadding dense>
                        <ListItem disablePadding>
                            <ListItemButton
                                dense
                                onClick={() => profileContext.signOut()}
                                sx={{
                                    color: 'var(--primary)',
                                    fontWeight: 'bold',
                                    border: '1px solid var(--primary)',
                                    mx: 1,
                                    borderRadius: '4px',
                                }}
                            >
                                <StyledCFFDrawerListItemIcon>
                                    <ExitToApp style={{ fontSize: '1.2rem' }} />
                                </StyledCFFDrawerListItemIcon>
                                <StyledCFFDrawerListItemText>
                                    {t("shared.logOut")}
                                </StyledCFFDrawerListItemText>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </List>
            </CFFDrawer>
            <Box component="main" sx={{ flexGrow: 1 }}>
                <Outlet />
            </Box>
        </Box>
    );
}