import { Add, Close, FilterAltOffOutlined, Refresh, Search } from '@mui/icons-material';
import { Box, Button, Checkbox, FormControl, Grid, Icon, IconButton, InputAdornment, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent, TextField, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { CategoryScale, Chart as ChartJS, Filler, Legend, LineElement, LinearScale, PointElement, Title } from 'chart.js';
import { t } from 'i18next';
import * as React from 'react';
import { Location, useLocation, useNavigate } from 'react-router-dom';

import { MdErrorOutline } from 'react-icons/md';
import { routes } from '../../../constants/appRoutes';
import { fundStatusType } from '../../../constants/appStates';
import { EmptyFundPage } from '../../../shared/components/Common/EmptyFundPage';
import { FundCard } from '../../../shared/components/Common/FundCard';
import { PageLoader } from '../../../shared/components/Common/PageLoader';
import { PageTitle } from '../../../shared/components/Common/PageTitle';
import { StyledPageContainer } from '../../../shared/components/Common/StyledPageContainer';
import { useDialogContext, useLoaderContext, useNotifyContext, useProfileContext } from '../../../shared/contexts';
import { DeleteFund, GetFund, GetFunds } from '../../../shared/helpers/fund.services';
import { useErrorTranslation } from '../../../shared/hooks';
import { useStandardCatch } from '../../../shared/hooks/useStandardCatch';
import { FundWithOperationApiDto } from '../../../shared/types/fund.types';
import { FundLocationState } from '../types/FundsPage.types';
import { FundDetailsTabs } from './FundDetailsPage';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Filler,
    Legend
);

interface Filters {
    status: string[]
    search: string;
}

export const useFundsPage = () => {

    const navigate = useNavigate();
    const location: Location<FundLocationState> = useLocation();
    const { state } = location;

    const notify = useNotifyContext();
    const { isAdmin } = useProfileContext();
    const getErrorTranslation = useErrorTranslation();
    const callErrorDialog = useDialogContext();
    const { setLoader } = useLoaderContext();
    const { baseCatch } = useStandardCatch();

    const [filters, setFilters] = React.useState<Filters>(state?.filters || { search: '', status: ['Current'] });
    const [tempFilters, setTempFilters] = React.useState<Omit<Filters, 'status'>>(state?.filters || { search: '' });
    const [fundsWithOperations, setFundsWithOperations] = React.useState<FundWithOperationApiDto[]>([]);
    const [pageLoader, setPageLoader] = React.useState(true);

    React.useEffect(() => {
        handleFundsReload();
    }, [filters]);

    const handleFundsReload = () => {

        setPageLoader(true);
        GetFunds({
            params: {
                name: filters.search,
                status: filters.status
            }
        })
            .then(({ data }) => {
                setPageLoader(false);
                setFundsWithOperations(data.fundsWithOperations);
            })
            .catch(ev => {
                setPageLoader(false);
                const status = ev.response?.data?.errorCode || ev.response?.status || 500;
                const url = ev.config.url;
                notify.warning({
                    content: getErrorTranslation(url, status)
                });
            });
    };

    const handleFilterReset = () => {
        setTempFilters({ search: '' });
        setFilters({ search: '', status: ['Current'] });
    };

    const handleSearch = () => {
        setFilters({
            ...filters,
            search: tempFilters.search
        });
    };

    const handleFundDetailsRedirect = (fundWithOperations: FundWithOperationApiDto, tab = FundDetailsTabs.Details) => {

        setPageLoader(true);

        GetFund({
            params: {
                oid: fundWithOperations.oid
            }
        })
            .then(({ data }) => {
                setPageLoader(false);
                navigate('/protected/funds/details', {
                    state: {
                        fund: {
                            ...data.fund,
                            balance: isAdmin ? data.fund.balance : 10000
                        },
                        filters,
                        tab
                    }
                });
            })
            .catch(ev => {
                setPageLoader(false);
                baseCatch(ev);
            });
    };

    const handleAddFund = () => {
        navigate('/protected/funds/details', { state: { filters } });
    };

    const handleDeleteClick = (fundWithOperations: FundWithOperationApiDto) => {

        callErrorDialog({
            open: true,
            title: t("fundsPage.dialogDeleteTitle"),
            content: t("fundsPage.dialogDeleteContent", { name: fundWithOperations.name }),
            yesCallback: (next) => {

                setLoader(true);
                DeleteFund({
                    params: {
                        fundId: fundWithOperations.oid,
                    }
                })
                    .then(() => {
                        notify.success({ content: t("success:deleteFund") });
                        handleFundsReload();
                        setLoader(false);
                        next();
                    })
                    .catch((ev) => {
                        setLoader(false);
                        baseCatch(ev);
                    });
            },
            noCallback: (next) => {
                next();
            }
        });
    };

    const handleSearchOnChange: React.ChangeEventHandler<HTMLInputElement> = (ev) => {
        setTempFilters({
            ...tempFilters,
            search: ev.target.value
        });
    };

    const handleSearchClear: React.MouseEventHandler<HTMLButtonElement> = (ev) => {
        setTempFilters({ ...tempFilters, search: '' });
        setFilters({ ...filters, search: '' });
    };

    const handleStatusMultiselectOnChange = (event: SelectChangeEvent<string[]>) => {
        const { target: { value } } = event;
        if ((typeof value === 'string' && value.split(',').length > 0) || value.length > 0) {
            setFilters({ ...filters, status: typeof value === 'string' ? value.split(',') : value });
        }
    };

    const handleSearchOnKeyUp: React.KeyboardEventHandler<HTMLInputElement> = (ev) => {
        const { key } = ev;
        if (key === 'Enter') {
            handleSearch();
        }
    };

    return {
        models: {
            filters,
            tempFilters,
            fundsWithOperations,
            loader: pageLoader
        },
        events: {
            handleSearchOnKeyUp,
            handleStatusMultiselectOnChange,
            handleRefresh: handleFundsReload,
            handleFilterReset,
            setFilters,
            handleSearch,
            handleSearchClear,
            handleSearchOnChange,
            handleFundDetailsRedirect,
            handleAddFund,
            handleDeleteClick
        }
    }
}

export const FundsPage = () => {

    const { isAdmin } = useProfileContext();
    const theme = useTheme();
    const smNoMatches = useMediaQuery(theme.breakpoints.up('md'));

    const {
        models: {
            fundsWithOperations,
            filters,
            loader,
            tempFilters
        },
        events: {
            handleSearchOnKeyUp,
            handleStatusMultiselectOnChange,
            handleFundDetailsRedirect,
            handleAddFund,
            handleSearchClear,
            handleSearch,
            handleSearchOnChange,
            handleDeleteClick,
            handleRefresh,
            handleFilterReset
        },
    } = useFundsPage();

    return (
        <StyledPageContainer>
            {smNoMatches && <PageTitle title={t("fundsPage.pageTitle")} />}
            {smNoMatches && (
                <Box display="flex" alignItems="center" gap={2} p={2}>
                    <Box display="flex" alignItems="center" flexWrap="wrap" gap={2} flexGrow={1}>
                        <TextField
                            label={t("fundsPage.search")}
                            disabled={loader}
                            sx={{ width: '240px' }}
                            onChange={handleSearchOnChange}
                            onKeyUp={handleSearchOnKeyUp}
                            value={tempFilters.search}
                            InputProps={{
                                startAdornment: tempFilters.search !== filters.search && (
                                    <InputAdornment position="start">
                                        <Tooltip title={t("shared.warningFilterNotApplied")}>
                                            <Icon fontSize="small">
                                                <MdErrorOutline color="var(--warning)" />
                                            </Icon>
                                        </Tooltip>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {tempFilters.search && (
                                            <IconButton
                                                disabled={loader}
                                                onClick={handleSearchClear}
                                                edge="end"
                                            >
                                                <Close />
                                            </IconButton>
                                        )}
                                        <IconButton
                                            disabled={loader}
                                            onClick={handleSearch}
                                            edge="end"
                                        >
                                            <Search />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <FormControl sx={{ width: '220px' }}>
                            <InputLabel sx={{ background: 'white', px: 1 }} shrink>Stato</InputLabel>
                            <Select
                                disabled={loader}
                                multiple
                                value={filters.status}
                                onChange={handleStatusMultiselectOnChange}
                                renderValue={(selected) => selected.map(name => t(`shared.fundStatus_${name}`)).join(', ')}
                            >
                                {[fundStatusType.closed, fundStatusType.current, fundStatusType.future].map((name, i) => (
                                    <MenuItem key={`menuitem-${i}`} value={name} dense disabled={loader} sx={{ padding: 0 } }>
                                        <Checkbox checked={filters.status.indexOf(name) > -1} />
                                        <ListItemText primary={t(`shared.fundStatus_${name}`)} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Tooltip title={t("fundsPage.TT_resetFilters")}>
                            <Box>
                                <IconButton disabled={loader} onClick={handleFilterReset} sx={{ p: 0 }}>
                                    <FilterAltOffOutlined />
                                </IconButton>
                            </Box>
                        </Tooltip>
                        <Tooltip title={t("fundsPage.TT_refreshAction")}>
                            <Box>
                                <IconButton size="small" disabled={loader} onClick={handleRefresh} sx={{ p: 0 }}>
                                    <Refresh />
                                </IconButton>
                            </Box>
                        </Tooltip>
                    </Box>
                    {isAdmin && (
                        <Button onClick={handleAddFund} startIcon={<Add />}>
                            {t('fundsPage.addFund')}
                        </Button>
                    )}
                </Box>
            )}
            <Box overflow="auto" height={smNoMatches ? "calc(100vh - 145px)" : "auto"} px={2}>
                <Grid container spacing={2} sx={{ height: '100%' }}>
                    {loader ? (
                        <Grid sm={12} item overflow="hidden">
                            <PageLoader />
                        </Grid>
                    ) : (
                        fundsWithOperations.length ? (
                            fundsWithOperations.map((fundWithOperations, i) => (
                                <Grid xs={12} sm={12} md={12} lg={6} xl={4} item key={`fundcard-${i}`}>
                                    <FundCard
                                        fundWithOperations={fundWithOperations}
                                        events={{
                                            handleDetailsClick: () => {
                                                handleFundDetailsRedirect(fundWithOperations);
                                            },
                                            handleDeleteClick: isAdmin ? () => {
                                                handleDeleteClick(fundWithOperations);
                                            } : undefined
                                        }}
                                    />
                                </Grid>
                            ))
                        ) : (
                            <Grid sm={12} item sx={{ height: '100%' }}>
                                <EmptyFundPage />
                            </Grid>
                        )
                    )}
                </Grid>
            </Box>
        </StyledPageContainer>
    );
};
