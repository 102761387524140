import { Box, Breadcrumbs, Link, Tab, Tabs, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledPageContainer } from '../../../shared/components/Common/StyledPageContainer';
import { BackTestsPanel } from './BackTestsPanel';
import { GeneralDetailsPanel } from './GeneralDetailsPanel';
import { OperationsPanel } from './OperationsPanel';
import { SecurityPanel } from './SecurityPanel';
import { StatisticsPanel } from './StatisticsPanel';

import { Location, useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../../constants/appRoutes";
import { PageTitle } from '../../../shared/components/Common/PageTitle';
import { useProfileContext } from "../../../shared/contexts";
import { FundDetailsTabValue, FundLocationState, FundModel } from "../types/FundsPage.types";

export const FundDetailsTabs: Record<'Details' | 'Statistics' | 'Security' | 'BackTests' | 'Operations', FundDetailsTabValue> = {
    Details: 0,
    Statistics: 1,
    Security: 2,
    BackTests: 3,
    Operations: 4
}

export const FundDetailsPage = () => {

    const { t } = useTranslation();
    const theme = useTheme();
    const smNoMatches = useMediaQuery(theme.breakpoints.up('sm'));

    const navigate = useNavigate();
    const { isAdmin } = useProfileContext();
    const location: Location<FundLocationState> = useLocation();

    const tab = location.state?.tab;

    const [fund, setFund] = React.useState<FundModel>(location.state?.fund ? {
        ...location.state.fund,
        balance: isAdmin ? location.state.fund.balance : 10000
    } : {
        oid: '',
        balance: 0,
        endDate: '',
        startDate: '',
        public: false,
        stopLoseTarget: 0,
        commission: 0,
        costs: 0,
        description: '',
        expectedProfit: 0,
        quotesAmount: 0,
        quotesExpenses: 0,
        quotesNumber: 0,
        ipCheck: false,
        logo: '',
        name: '',
        takeProfitTarget: 0,
    });

    const [value, setValue] = React.useState<number>(tab || FundDetailsTabs.Details);

    const handleChange = (_: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleRedirect = () => {
        navigate(`/protected/${routes.funds}`, location);
    }

    return (
        <StyledPageContainer>
            <PageTitle
                title={t("fundsPage.pageTitle")}
                breadcrumbs={(
                    <Breadcrumbs>
                        <Link onClick={handleRedirect} sx={{ fontSize: '0.8rem' }} >
                            Fondi e operazioni
                        </Link>
                        <Typography sx={{ fontSize: '0.8rem', color: '#1c6e3d', fontWeight: '500', wordBreak: 'break-all' }}>
                            {fund?.name ? fund.name : t("fundDetailsPage.new")}
                        </Typography>
                    </Breadcrumbs>
                )}
            />

            <Box>
                {fund?.oid && (
                    <Tabs value={value} onChange={handleChange}>
                        <Tab label={t("fundDetailsPage.details")} value={FundDetailsTabs.Details} />
                        {fund?.oid && isAdmin && <Tab label={t("fundDetailsPage.security")} value={FundDetailsTabs.Security} />}
                        {fund?.oid && <Tab label={t("fundDetailsPage.statistics")} value={FundDetailsTabs.Statistics} />}
                        {fund?.oid && <Tab label={t("fundDetailsPage.backtests")} value={FundDetailsTabs.BackTests} />}
                        {fund?.oid && isAdmin && <Tab label={t("fundDetailsPage.operations")} value={FundDetailsTabs.Operations} />}
                    </Tabs>
                )}
                <Box
                    sx={{
                        boxSizing: 'border-box',
                        overflow: 'auto',
                        paddingTop: '8px',
                        borderTop: '1px solid var(--primary-wo)',
                        height: smNoMatches ? 'calc(100vh - 132px)' : 'auto',
                    }}
                >
                    {value === FundDetailsTabs.Details && (
                        <GeneralDetailsPanel setFund={setFund} fund={fund} />
                    )}

                    {value === FundDetailsTabs.Statistics && fund?.oid && (
                        <StatisticsPanel setFund={setFund} fund={fund} />
                    )}

                    {value === FundDetailsTabs.Security && fund?.oid && (
                        <SecurityPanel setFund={setFund} fund={fund} />
                    )}

                    {value === FundDetailsTabs.BackTests && fund?.oid && (
                        <BackTestsPanel setFund={setFund} fund={fund} />
                    )}

                    {value === FundDetailsTabs.Operations && fund?.oid && (
                        <OperationsPanel setFund={setFund} fund={fund} />
                    )}
                </Box>
            </Box>
        </StyledPageContainer>
    );
};
