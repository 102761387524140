import React from "react";
import { useNavigate } from "react-router";
import { SignOut } from "../helpers/auth.services";
import { appRoles } from "../../constants/appRoles";
import { CFF } from "../types";

type SignOutParameters = {
    noRedirect?: boolean;
};

type ProfileContextType = {
    isInvestor: boolean;
    isGuest: boolean;
    isAdmin: boolean;
    profile: CFF.ApplicationUser;
    setProfile: React.Dispatch<React.SetStateAction<CFF.ApplicationUser>>;
    signOut: (params?: SignOutParameters) => Promise<unknown>;
};

const ProfileContext = React.createContext<ProfileContextType>({} as ProfileContextType);

export const ProfileContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {

    const navigate = useNavigate();

    const [profile, setProfile] = React.useState<CFF.ApplicationUser>({} as CFF.ApplicationUser);

    const signOut = React.useCallback((params?: SignOutParameters) => {

        return SignOut()
            .finally(() => {
                setProfile({} as CFF.ApplicationUser);
                if (!params?.noRedirect) {
                    navigate("/", { replace: true });
                }
            });
    }, []);

    const isAdmin = React.useMemo(() => {
        return profile.roles?.includes(appRoles.ADMIN);
    }, [profile]);
    const isGuest = React.useMemo(() => {
        return profile.roles?.includes(appRoles.GUEST);
    }, [profile]);
    const isInvestor = React.useMemo(() => {
        return profile.roles?.includes(appRoles.INVESTOR);
    }, [profile]);

    return (
        <ProfileContext.Provider
            value={{
                profile,
                setProfile,
                signOut,
                isAdmin,
                isInvestor,
                isGuest,
            }}
        >
            {children}
        </ProfileContext.Provider>
    );
}

export const useProfileContext = () => React.useContext(ProfileContext);
