import { Refresh } from '@mui/icons-material';
import { Box, Grid, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CFFDateFormat } from '../../../constants/appRegex';
import { RT, TFTable } from '../../../shared/components';
import { useNotifyContext } from '../../../shared/contexts';
import { GetOperations } from '../../../shared/helpers/fund.services';
import { useErrorTranslation } from '../../../shared/hooks';
import { useMathUtilities } from '../../../shared/hooks/useMathUtilities';
import { DetailsPanelProps, FundOperationApiDto } from '../types/FundsPage.types';

export const OperationsPanel: React.FC<DetailsPanelProps> = (props) => {

    const { t } = useTranslation();
    const notify = useNotifyContext();
    const { toLocalePrice } = useMathUtilities();
    const getErrorTranslation = useErrorTranslation();
    const tableRef = React.useRef() as React.MutableRefObject<RT<FundOperationApiDto>>;

    return (
        <Box px={2} sx={{ width: 'calc(100vw - 282px)' }}>
            <Grid spacing={2} container>
                <Grid xs={12} sm={12} md={12} item>
                    <TFTable<FundOperationApiDto>
                        ref={tableRef}
                        initialize={{
                            sorting: {
                                field: 'Ticket',
                                order: 'Desc',
                            }
                        }}
                        data={(query) => {

                            return new Promise((result) => {

                                GetOperations({
                                    params: {
                                        orderBy: query?.sorting?.field,
                                        orderByDescending: query?.sorting?.order === 'Desc',
                                        fundOid: props.fund?.oid || '',
                                        pageNr: query?.pageNumber,
                                        pageSize: query?.pageSize,
                                    }
                                }).then(({ data }) => {

                                    result({
                                        data: data.operations,
                                        totalCount: data.totalCount
                                    });
                                }).catch((ev) => {

                                    const status = ev.response?.data?.errorCode || ev.response?.status || 500;
                                    const url = ev.config.url;
                                    notify.warning({
                                        content: getErrorTranslation(url, status)
                                    });
                                });
                            })
                        }}
                        actions={[
                            {
                                icon: <Refresh />,
                                tooltip: t("shared.table.TT_refresh"),
                                onClick: () => {
                                    tableRef.current.reload();
                                }
                            }
                        ]}
                        options={{
                            usePagination: true,
                            useSearch: false,
                            localize: {
                                noData: t('shared.table.gridEmptyMsg'),
                                resetFilters: t('shared.table.resetFilters'),
                                search: t('shared.table.search'),
                                displayedElementsSeparator: t('shared.table.displayedElementsSeparator'),
                                rowsForPage: t('shared.table.rowsForPage'),
                            },
                            minWidth: "1000px",
                            style: {
                                maxHeight: "calc(100vh - 242px)"
                            },
                            filtersAdjustmentStyle: {
                                top: '40px'
                            }
                        }}
                        columns={[
                            {
                                title: () => t("fundDetailsPage.ticket"),
                                render: ({ ticket }) => ticket,
                                thStyle: { width: 80, textAlign: 'right', },
                                style: { width: 80, textAlign: 'right', },
                            },
                            {
                                title: () => t("fundDetailsPage.asset"),
                                render: ({ asset }) => asset,
                            },
                            {
                                title: () => t("fundDetailsPage.operationType"),
                                render: ({ operationType }) => operationType,
                                thStyle: { width: 70, },
                                style: { width: 70, }
                            },
                            {
                                title: () => t("fundDetailsPage.lotSizes"),
                                render: ({ lotSize }) => toLocalePrice(lotSize, 2, false),
                                style: { textAlign: 'right' },
                                thStyle: { textAlign: 'right', width: 50 },
                            },
                            {
                                title: () => t("fundDetailsPage.shortOpenPrice"),
                                render: ({ openPrice, openPriceDate }) => {
                                    return openPrice ? (
                                        <Tooltip title={moment(openPriceDate).format(CFFDateFormat.long)}>
                                            <Box>{toLocalePrice(openPrice, 5, false)}</Box>
                                        </Tooltip>
                                    ) : false;
                                },
                                style: { textAlign: 'right' },
                                thStyle: { textAlign: 'right' },
                            },
                            {
                                title: () => t("fundDetailsPage.shortClosePrice"),
                                render: ({ closePrice, closePriceDate }) => {
                                    return closePrice ? (
                                        <Tooltip title={moment(closePriceDate).format(CFFDateFormat.long)}>
                                            <Box>{toLocalePrice(closePrice, 5, false)}</Box>
                                        </Tooltip>
                                    ) : false;
                                },
                                thStyle: { textAlign: 'right' },
                                style: { textAlign: 'right' }
                            },
                            {
                                title: () => t("fundDetailsPage.shortProfit"),
                                render: ({ profit }) => toLocalePrice(profit),
                                thStyle: { textAlign: 'right', width: '70px' },
                                style: { textAlign: 'right' },
                            },
                            {
                                title: () => t("fundDetailsPage.shortCommission"),
                                render: ({ commission }) => toLocalePrice(commission, 2, false),
                                thStyle: { textAlign: 'right', width: '100px' },
                                style: { textAlign: 'right' },
                            },
                            {
                                title: () => t("fundDetailsPage.swap"),
                                render: ({ swap }) => toLocalePrice(swap, 2, false),
                                thStyle: { textAlign: 'right', width: 78 },
                                style: { textAlign: 'right' },
                            },
                            {
                                title: () => t("fundDetailsPage.shortNetProfit"),
                                render: ({ netProfit }) => toLocalePrice(netProfit),
                                thStyle: { textAlign: 'right', width: '70px' },
                                style: { textAlign: 'right' },
                            }
                        ]}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}
