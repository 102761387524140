import _ from "lodash";
import * as React from "react";

type CommonValue = string | boolean | null | CommonValue[] | undefined;
type CommonCallback<R> = (state: R) => void;
type Validators<R> = Record<string, (state: R) => CommonValue>;
type Errors<R> = Record<keyof R | string, CommonValue>;
type FHChangeEvent = (path: string, value: CommonValue) => void;
type FHValidateEvent<R> = (complete: CommonCallback<R>, fallback?: CommonCallback<R>) => void;

const initBeforeUnLoad = (bool: boolean) => {
    window.onbeforeunload = (event: BeforeUnloadEvent) => {
        if (bool) {
            const e = event || window.event;
            e.preventDefault();
            if (e) e.returnValue = '';
            return '';
        }
    };
};

const useExitPrompt = (bool: boolean, enabled?: boolean) => {

    React.useEffect(() => {
        if (enabled) {
            window.onload = () => {
                initBeforeUnLoad(bool)
            };
            if (bool) {
                window.history.pushState = (event) => {
                    const e = event || window.event;
                    e.preventDefault();
                    if (e) e.returnValue = '';
                    return '';
                };
            } 
            initBeforeUnLoad(bool);
        }
    }, [bool]);
}

/**
 * Hook di utility per la gestione dello stato di form con delle propriet� da validare
 * @param initialize Modello utilizzato per l'inizializzazione di stato/errori e tipizzazione
 * @param validators Definisce i modelli da utilizzare per la validazione delle parti di stato
 * 
 * Nota: 
 * L'utilizzo di questo hook funziona bene se non ci sono stati troppo grandi o complessi da gestire
 */
export const useFormHelpers = <R extends object>(
    initialize: R,
    validators?: Validators<R>,
    config?: {
        useExitPrompt?: boolean;
    }
) => {

    const stateGuardRef = React.useRef({ confirmedState: initialize });

    const [state, setState] = React.useState<R>(initialize);
    const [errors, setErrors] = React.useState<Errors<R>>({} as Errors<R>);

    /**
     * Variabile di controllo, true se lo stato cambia rispetto il valore inziale o 
     * al valore confermato dopo la callback di completamento
     */
    const isChanged = React.useMemo(() => { return !_.isEqual(stateGuardRef.current.confirmedState, state); }, [state, stateGuardRef.current.confirmedState]);

    // Hook di utility per la gestione dei prompt di conferma modifiche in corso
    useExitPrompt(isChanged, config?.useExitPrompt);    

    /**
     * Evento generico per la modifica sullo stato della form
     * @param path nome/percorso della propriet� in formato stringa. example: prop1.prop2
     * @param value
     */
    const handleChange: FHChangeEvent = React.useCallback((path, value) => {
        const newState = _.set<R>({ ...state }, path, value);
        setState(newState);
        const correctValidator = _.get(validators, path);
        if (!!correctValidator) {
            const newErrors = _.set({ ...errors }, path, correctValidator(newState));
            setErrors(newErrors);
        }
    }, [validators, state, errors]);

    /**
     * Evento utilizzato per la validazione dello stato.
     * Verifica sulla base delle validazioni se sono presenti errori ed esegue una callback di completamento
     * altrimenti esegue la callback di fallback
     * @param complete Callback di completamento da eseguire se vengono passate le validazioni
     */
    const validate: FHValidateEvent<R> = React.useCallback((complete, fallback) => {
        const executeCompleteCallback = (state: R) => {
            stateGuardRef.current.confirmedState = { ...state };
            complete(state);
        }
        if (validators) {
            let _errors = { ...errors };
            Object
                .keys(validators)
                .forEach((key) => {
                    const correctValidator = _.get(validators, key);
                    if (!!correctValidator) {
                        _.set<R>(_errors, key, correctValidator(state));
                    }
                });
            setErrors({ ..._errors });
            const hasErrors = _.values(_errors);
            const counter = _.some(hasErrors, error => error);
            if (counter && fallback) {
                fallback(state);
            } else if (!counter) {
                executeCompleteCallback(state);
            }
        } else {
            executeCompleteCallback(state);
        }
    }, [validators, state, errors]);

    //#region Eventi di utility
    const namedTextFieldOnChange: React.ChangeEventHandler<HTMLInputElement> = React.useCallback((event) => {
        const { name, value } = event.target;
        if (name) handleChange(name, value);
    }, [handleChange]);

    const namedSwitchOnChange: React.ChangeEventHandler<HTMLInputElement> = React.useCallback((event) => {
        const { name, checked } = event.target;
        if(name) handleChange(name, checked);
    }, [handleChange]);

    const clear = React.useCallback(() => {
        setState({ ...initialize });
        setErrors({} as Errors<R>);
    }, []);
    //#endregion

    return {
        state,
        errors,
        setState,
        setErrors,
        validate,
        handleChange,
        validators,
        utility: {
            namedTextFieldOnChange,
            namedSwitchOnChange,
            isChanged,
            clear
        }
    }
}
