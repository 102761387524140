import { Box, Button, Container, FormControlLabel, Grid, Icon, IconButton, Stack, Switch, TextField } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaRegCopy } from 'react-icons/fa';
import { IoWalletSharp } from 'react-icons/io5';

import { StyledDescriptionText } from '../../../shared/components/Common/StyledDescriptionText';
import { useLoaderContext, useNotifyContext } from '../../../shared/contexts';
import { AddWallet, UpdateWallet } from '../../../shared/helpers/wallets.services';
import { useFormHelpers } from '../../../shared/hooks';
import { useStandardCatch } from '../../../shared/hooks/useStandardCatch';
import { DetailsPanelProps, Wallet } from '../types/WalletsPage.types';

export const useGeneralDetailsPanel = (props: DetailsPanelProps) => {

    const { t } = useTranslation();

    const notify = useNotifyContext();
    const { setLoader } = useLoaderContext();
    const { baseCatch } = useStandardCatch();


    const fundFormController = useFormHelpers(
        props.wallet,
        {
            name: (state: Wallet) => !state.name ? t("validations.name") : false,
        }
    );

    const handleFieldChange: React.ChangeEventHandler<HTMLInputElement> = React.useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = ev.target;
        fundFormController.handleChange(name, value);
    }, [fundFormController]);

    const handleSubmitForm = (ev: any) => {

        ev.preventDefault();

        fundFormController.validate((state) => {

            setLoader(true);

            if (!!state.code) {

                UpdateWallet({
                    params: {
                        code: state.code
                    },
                    data: state
                })
                    .then(() => {
                        notify.success({
                            content: t("success:updateWallet")
                        });
                        props.setWallet(state);
                        setLoader(false);
                    })
                    .catch(ev => {
                        setLoader(false);
                        baseCatch(ev, '/api/wallets');
                    });
            } else {

                AddWallet({
                    data: state
                })
                    .then(({ data }) => {
                        fundFormController.setState({ ...state, code: data.code });
                        notify.success({
                            content: t("success:addWallet")
                        });
                        props.setWallet({ ...state, code: data.code });
                        setLoader(false);
                    })
                    .catch(ev => {
                        setLoader(false);
                        baseCatch(ev, '/api/wallets');
                    });
            }
        });
    };

    const handleEnabledSwitchOnChange = React.useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
        fundFormController.setState({ ...fundFormController.state, enabled: ev.target.checked });
    }, [fundFormController]);

    return {
        models: {
            formModel: fundFormController.state,
            formErrorModel: fundFormController.errors,
        },
        events: {
            handleFieldChange,
            handleSubmitForm,
            handleEnabledSwitchOnChange,
        }
    };
};

export const GeneralDetailsPanel: React.FC<DetailsPanelProps> = (props) => {

    const { t } = useTranslation();

    const {
        models: {
            formModel,
            formErrorModel,
        },
        events: {
            handleFieldChange,
            handleSubmitForm,
            handleEnabledSwitchOnChange,
        }
    } = useGeneralDetailsPanel(props);

    return (
        <Container maxWidth="md">
            <Grid spacing={2} container pt={2}>
                <Grid md={2} item>
                    <Box sx={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                        <Box
                            sx={{
                                width: '100px',
                                height: '100px',
                                borderRadius: '50%',
                                border: '1px solid #ddd',
                                cursor: 'pointer',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Icon
                                sx={{
                                    color: "#222",
                                    fill: '#222',
                                    fontSize: '1.5rem'
                                }}
                            >
                                <IoWalletSharp />
                            </Icon>
                        </Box>
                    </Box>
                </Grid>
                <Grid md={10} item>
                    <Grid container spacing={2}>
                        <Grid xs={12} sm={12} md={12} item>
                            <StyledDescriptionText>
                                {!!formModel.code && (
                                    <IconButton
                                        onClick={async (data) => {
                                            const type = "text/plain";
                                            var blob = new Blob([String(formModel.code)], { type });
                                            await navigator.clipboard.write([new ClipboardItem({ [type]: blob })]);
                                        }}
                                    >
                                        <FaRegCopy style={{ fill: '#1c6e3d' }} />
                                    </IconButton>
                                )}
                                {t("shared.code")}: {formModel.code || 'xxxxxxxxx'}
                            </StyledDescriptionText>
                        </Grid>
                        <Grid xs={12} sm={12} md={9} item>
                            <TextField
                                label={`${t("walletDetailsPage.name")}*`}
                                name="name"
                                value={formModel.name || ''}
                                error={Boolean(formErrorModel?.name)}
                                helperText={formErrorModel.name}
                                onChange={handleFieldChange}
                            />
                        </Grid>
                        <Grid xs={12} sm={12} md={4} lg={3} item>
                            <FormControlLabel
                                sx={{ mt: 1 }}
                                label={`${t("walletDetailsPage.enabled")}`}
                                control={(
                                    <Switch
                                        checked={formModel.enabled}
                                        onChange={handleEnabledSwitchOnChange}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid xs={12} sm={12} md={12} item>
                    <TextField
                        label={`${t("walletDetailsPage.description")}`}
                        name="description"
                        onChange={handleFieldChange}
                        value={formModel.description || ''}
                        error={Boolean(formErrorModel?.description)}
                        helperText={formErrorModel.description}
                        multiline
                        rows={3}
                    />
                </Grid>
                <Grid md={12} item>
                    <Stack spacing={2} direction="row" justifyContent="end" alignItems="center">
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <Button onClick={handleSubmitForm} >
                                {t("shared.save")}
                            </Button>
                        </Box>
                    </Stack>
                </Grid>
            </Grid>
        </Container>
    );
}
