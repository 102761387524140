import React from 'react';
import { Route } from 'react-router-dom';
import { routes } from '../../constants/appRoutes';
import { WalletDetailsPage } from './components/WalletDetailsPage';
import { WalletsPage } from './components/WalletsPage';
import { WalletsPageOutlet } from './routes/WalletsPageOutlet';

export const AdminWalletsViewPageRoutes = (
    <Route path={routes.users + '/' + routes.adminWalletsView} element={<WalletsPageOutlet />}>
        <Route index element={<WalletsPage />} />
        <Route path={routes.walletDetails} element={<WalletDetailsPage />} />
    </Route>
)