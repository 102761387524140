import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Button, IconButton, InputAdornment, Link, TextField } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CFFRegex } from '../../constants/appRegex';
import { routes } from '../../constants/appRoutes';
import { useLoaderContext } from '../../shared/contexts';
import { SignIn } from '../../shared/helpers/auth.services';
import { useForm } from '../../shared/hooks/useForm';
import { useStandardCatch } from '../../shared/hooks/useStandardCatch';
import { useTextFieldVisibility } from '../../shared/hooks/useTextFieldVisibility';

const useLogInPage = () => {

    const navigate = useNavigate();

    const { setLoader } = useLoaderContext();
    const { baseCatch } = useStandardCatch();

    const { register, submit } = useForm({ email: '', password: '' });

    const handleSubmitForm = submit((state) => {
        setLoader(true);
        SignIn({
            data: {
                email: state.email,
                password: state.password,
            }
        }).then(() => {
            navigate(routes.protected);
            setLoader(false);
        }).catch((ev) => {
            setLoader(false);
            baseCatch(ev);
        });
    });

    const handleForgotPassword = React.useCallback(() => {
        navigate(routes.forgotPassword);
    }, [navigate]);

    return {
        helpers: {
            register,
        },
        events: {
            handleSubmitForm,
            handleForgotPassword
        },
    };
};

export const LogInPage = () => {

    const { t } = useTranslation();

    const { visibility, setVisibility } = useTextFieldVisibility();
    const {
        helpers: {
            register
        },
        events: {
            handleSubmitForm,
            handleForgotPassword
        },
    } = useLogInPage();

    return (
        <Box
            display="flex"
            flexDirection="column"
            gap={2}
            component="form"
            autoComplete="on"
            onSubmit={handleSubmitForm}
        >
            <TextField
                autoComplete="username"
                {...register('email', {
                    label: `${t('logInPage.email')}*`,
                    pattern: ({ email }) => ({
                        email: !CFFRegex.email.test(email) && t("validations.email")
                    })
                })}
            />
            <TextField
                autoComplete="current-password"
                type={visibility ? 'text' : 'password'}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={setVisibility} edge="end">
                                {visibility ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                {...register('password', {
                    label: `${t('logInPage.password')}*`,
                    pattern: ({ password }) => ({
                        password: !password && t("validations.password")
                    })
                })}
            />
            <Button type="submit" fullWidth>
                {t('logInPage.logIn')}
            </Button>
            <Link onClick={handleForgotPassword}>
                {t('logInPage.forgotPassword')}
            </Link>
        </Box>
    );
};
