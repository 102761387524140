import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { ThemeProvider } from '@mui/material';
import * as React from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { App } from './App';
import './assets/css/custom.css';
import { appMuiTheme } from './assets/theme';
import {
    ErrorDialogContext,
    LoaderContextProvider,
    NotifyContextProvider,
    ProfileContextProvider
} from './shared/contexts';

const rootElement = document.getElementById('root')!;
const root = createRoot(rootElement);

root.render(
    <BrowserRouter>
        <ThemeProvider theme={appMuiTheme}>
            <ProfileContextProvider>
                <LoaderContextProvider>
                    <NotifyContextProvider>
                        <ErrorDialogContext>
                            <App />
                        </ErrorDialogContext>
                    </NotifyContextProvider>
                </LoaderContextProvider>
            </ProfileContextProvider>
        </ThemeProvider>
    </BrowserRouter>
);
