import { Box, Icon, styled } from '@mui/material';
import moment from "moment";
import { default as React } from 'react';
import { useTranslation } from 'react-i18next';
import { FaWallet } from "react-icons/fa";
import { CFFDateFormat } from '../../../constants/appRegex';
import { useMathUtilities } from '../../hooks/useMathUtilities';
import { WalletApiDto } from '../../types/wallets.types';

const StyledContainer = styled(Box)<{ status: string }>(({ status }) => ({
    boxSizing: 'border-box',
    //background: `linear-gradient(#fff 50%, #ddd)`,
    border: `2px solid #ddd`,
    padding: '16px',
    color: '#000',
    borderRadius: '4px',
    cursor: 'pointer',
    gap: 1,
    minHeight: '105px',
    display: 'flex',
    flexDirection: 'column',
    ':hover': {
        opacity: 0.7
    }
}));

export interface FundCardProps {
    wallet: WalletApiDto;
    events?: {
        handleDetailsClick?: (wallet: WalletApiDto) => void;
    }
}

export const WalletCard = (props: FundCardProps) => {

    const { events, wallet } = props;

    const { t } = useTranslation();

    const mathUtilities = useMathUtilities();

    return (
        <StyledContainer
            status={`wallet-enabled-${wallet.enabled}`}
            onClick={() => {
                if (events?.handleDetailsClick)
                    events?.handleDetailsClick(wallet)
            }}
        >
            <Box display="flex" flexDirection="column" justifyContent="center" gap={1}>
                <Box display="flex" alignItems="center" gap={1}>
                    <Icon fontSize="medium">
                        <FaWallet />
                    </Icon>
                    <Box display="flex" flexDirection="column" fontSize="1rem" flexGrow={1}>
                        <Box display="flex" alignItems="center" justifyContent="space-between" fontWeight={500} >
                            <Box flexGrow={1} sx={{ wordBreak: 'break-all' }}>
                                {wallet.name}
                            </Box>
                            <Box sx={{ wordBreak: 'break-all' }}>
                                {mathUtilities.toLocalePrice(wallet?.amount)}€
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box display="flex" flexDirection="column">
                    <em style={{ fontWeight: '400', fontSize: '0.8rem', flexGrow: 1 }}>
                        {t("walletsPage.creationDate", {
                            creationDate: moment(wallet.creationDate).format(CFFDateFormat.long),
                        })}
                    </em>
                    {!wallet.enabled && (
                        <em style={{ fontWeight: '400', fontSize: '0.8rem', flexGrow: 1 }}>
                            {t("walletsPage.deactivationDate", {
                                deactivationDate: moment(wallet.deactivationDate).format(CFFDateFormat.long),
                            })}
                        </em>
                    )}
                </Box>
            </Box>
        </StyledContainer>
    );
}
