import React from 'react';
import { Route } from 'react-router-dom';
import { routes } from '../../constants/appRoutes';
import { FundDetailsPage } from './components/FundDetailsPage';
import { FundsPage } from './components/FundsPage';
import { FundsPageOutlet } from './routes/FundsPageOutlet';

export const FundsPageRoutes = (
<Route path={routes.funds} element={<FundsPageOutlet />}>
    <Route index element={<FundsPage />} />
    <Route path={routes.fundDetails} element={<FundDetailsPage />} />
</Route>
)