import { Box, Icon } from "@mui/material";
import { StyledDescriptionText } from "./StyledDescriptionText";
import { MdPlaylistRemove } from "react-icons/md";
import React from "react";
import { useTranslation } from "react-i18next";

export const EmptyFundPage: React.FC<{ title?: string }> = ({ title }) => {

    const { t } = useTranslation();

    return (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', gap: 2, flexDirection: 'column', alignItems: 'center' }}>
            <Icon sx={{ fontSize: '10rem', color: '#ddd' }}>
                <MdPlaylistRemove />
            </Icon>
            <StyledDescriptionText sx={{ color: '#ddd', fontSize: '2rem', textAlign: 'center' }}>
                {title || t('homePage.noFunds')}
            </StyledDescriptionText>
        </Box>
    );
}