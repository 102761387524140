import { Box, Button, Container, FormControlLabel, Grid, Icon, IconButton, Stack, Switch, TextField } from '@mui/material';
import moment from 'moment';
import React from 'react';
import DatePicker from "react-datepicker";
import { useTranslation } from 'react-i18next';
import { FaRegCopy } from 'react-icons/fa';
import { FaSackDollar, FaUpload } from 'react-icons/fa6';
import { Location, useLocation, useNavigate } from 'react-router-dom';
import { routes } from '../../../constants/appRoutes';
import { CustomDateInput } from '../../../shared/components/Common/CustomDateInput';
import { StyledDescriptionText } from '../../../shared/components/Common/StyledDescriptionText';
import { useDialogContext, useLoaderContext, useNotifyContext, useProfileContext } from '../../../shared/contexts';
import { AddFund, DeleteFund, UpdateFund } from '../../../shared/helpers/fund.services';
import { useErrorTranslation, useFormHelpers } from '../../../shared/hooks';
import { DetailsPanelProps, FundLocationState, FundModel } from '../types/FundsPage.types';
import { useStandardCatch } from '../../../shared/hooks/useStandardCatch';

export const useGeneralDetailsPanel = (props: DetailsPanelProps) => {

    const navigate = useNavigate();
    const location: Location<FundLocationState> = useLocation();

    const { t } = useTranslation();

    const notify = useNotifyContext();
    const { isAdmin } = useProfileContext();
    const { setLoader } = useLoaderContext();
    const getErrorTranslation = useErrorTranslation();
    const callErrorDialog = useDialogContext();
    const { baseCatch } = useStandardCatch();

    const fundFormController = useFormHelpers(
        {
            ...props.fund,
            balance: isAdmin ? props.fund.balance : 10000
        },
        {
            name: (state: FundModel) => !state.name ? t("validations.name") : false,
            startDate: (state: FundModel) => !state.startDate ? t("validations.startDate") : false,
            endDate: (state: FundModel) => !state.endDate ? t("validations.endDate") : false,
            balance: (state: FundModel) => !(state.balance > 0) ? t("validations.balance") : false,
            expectedProfit: (state: FundModel) => !(state.expectedProfit > 0) ? t("validations.invalidNumber") : false,
            takeProfitTarget: (state: FundModel) => !(state.takeProfitTarget >= 0) ? t("validations.invalidNumber") : false,
            stopLoseTarget: (state: FundModel) => !(state.stopLoseTarget >= 0) ? t("validations.invalidNumber") : false,
            quotesExpenses: (state: FundModel) => !(state.quotesExpenses >= 0) ? t("validations.invalidNumber") : false,
            costs: (state: FundModel) => !(state.costs >= 0) ? t("validations.invalidNumber") : false,
            commission: (state: FundModel) => !(state.commission >= 0) ? t("validations.invalidNumber") : false,
            quotesAmount: (state: FundModel) => !(state.quotesAmount >= 0) ? t("validations.invalidNumber") : false,
        }
    );

    const handleFieldChange: React.ChangeEventHandler<HTMLInputElement> = React.useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = ev.target;
        fundFormController.handleChange(name, value);
    }, [fundFormController]);

    const handleSubmitForm = (ev: any) => {

        ev.preventDefault();

        fundFormController.validate((state) => {

            setLoader(true);

            if (state.oid) {

                UpdateFund({
                    params: {
                        oid: state.oid
                    },
                    data: state
                })
                    .then(() => {
                        notify.success({
                            content: t("success:updateFund")
                        });
                        props.setFund(state);
                        setLoader(false);
                    })
                    .catch(ev => {
                        setLoader(false);
                        const url = '/api/funds';
                        baseCatch(ev, url);
                    });
            } else {

                AddFund({
                    data: state
                })
                    .then(({ data }) => {
                        fundFormController.setState({ ...state, oid: data.oid });
                        notify.success({
                            content: t("success:addFund")
                        });
                        props.setFund({ ...state, oid: data.oid });
                        setLoader(false);
                    })
                    .catch(ev => {
                        setLoader(false);
                        const url = '/api/funds';
                        baseCatch(ev, url);
                    });
            }
        });
    };

    const handleFileChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {

        let reader = new FileReader();
        if (event.currentTarget.files?.length) {
            const file = Object(event.currentTarget.files)[0];
            reader.readAsDataURL(file);
            reader.onload = function () {
                fundFormController.setState({ ...fundFormController.state, logo: String(reader.result) });
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
            };
        }
    }, [fundFormController, getErrorTranslation, navigate, notify, setLoader]);

    const triggerFileUpload = React.useCallback(() => {
        document.getElementById("fileLoader")?.click();
    }, []);

    const handleDeleteClick = React.useCallback(() => {

        callErrorDialog({
            open: true,
            title: t("fundsPage.dialogDeleteTitle"),
            content: t("fundsPage.dialogDeleteContent", { name: fundFormController.state.name }),
            yesCallback: (next) => {

                setLoader(true);
                DeleteFund({
                    params: {
                        fundId: fundFormController.state.oid,
                    }
                })
                    .then(() => {
                        notify.success({
                            content: t("success:deleteFund")
                        });
                        setLoader(false);
                        next();
                        navigate(`/protected/${routes.funds}`, location);
                    })
                    .catch(ev => {
                        setLoader(false);
                        const status = ev.response?.data?.errorCode || ev.response?.status || 500;
                        const url = ev.config.url;
                        notify.warning({
                            content: getErrorTranslation(url, status)
                        });
                    })
            },
            noCallback: (next) => {
                next();
            }
        });
    }, []);

    const handleStartDateOnChange = React.useCallback((date: Date | null) => {
        const startDate = date?.toISOString() || '';
        const updatedState = { ...fundFormController.state, startDate };
        fundFormController.setState(updatedState);
        fundFormController.setErrors({ ...fundFormController.errors, startDate: fundFormController.validators?.startDate(updatedState) });
    }, [fundFormController]);

    const handleEndDateOnChange = React.useCallback((date: Date | null) => {
        const endDate = date?.toISOString() || '';
        const updatedState = { ...fundFormController.state, endDate };
        fundFormController.setState(updatedState);
        fundFormController.setErrors({ ...fundFormController.errors, endDate: fundFormController.validators?.endDate(updatedState) });
    }, [fundFormController]);

    const handleStartDateClear = React.useCallback(() => {
        const state = { ...fundFormController.state, startDate: '' };
        fundFormController.setState(state);
        fundFormController.setErrors({ ...fundFormController.errors, startDate: fundFormController.validators?.startDate(state) });
    }, [fundFormController]);

    const handleEndDateClear = React.useCallback(() => {
        const state = { ...fundFormController.state, endDate: '' };
        fundFormController.setState(state);
        fundFormController.setErrors({ ...fundFormController.errors, endDate: fundFormController.validators?.endDate(state) });
    }, [fundFormController]);

    const handlePublicSwitchOnChange = React.useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
        fundFormController.setState({ ...fundFormController.state, public: ev.target.checked });
    }, [fundFormController]);


    return {
        models: {
            formModel: fundFormController.state,
            formErrorModel: fundFormController.errors,
        },
        events: {
            triggerFileUpload,
            handlePublicSwitchOnChange,
            handleFileChange,
            handleFieldChange,
            handleSubmitForm,
            handleDeleteClick,
            handleStartDateOnChange,
            handleStartDateClear,
            handleEndDateClear,
            handleEndDateOnChange
        }
    };
};

export const GeneralDetailsPanel: React.FC<DetailsPanelProps> = (props) => {

    const { t } = useTranslation();
    const { isAdmin } = useProfileContext();

    const {
        models: {
            formModel,
            formErrorModel,
        },
        events: {
            handlePublicSwitchOnChange,
            triggerFileUpload,
            handleFileChange,
            handleFieldChange,
            handleSubmitForm,
            handleDeleteClick,
            handleStartDateOnChange,
            handleStartDateClear,
            handleEndDateClear,
            handleEndDateOnChange
        }
    } = useGeneralDetailsPanel(props);

    return (
        <Box px={2}>
                <Grid spacing={2} container pt={2}>
                    <Grid md={2} item>
                        <Box sx={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                            <Box
                                onClick={triggerFileUpload}
                                sx={{
                                    width: '100px',
                                    height: '100px',
                                    borderRadius: '50%',
                                    border: '1px solid #ddd',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    '.img2': {
                                        display: 'none'
                                    },
                                    ':hover': {
                                        ...(!isAdmin ? {} : {
                                            opacity: 0.3,
                                            '.img': {
                                                display: 'none'
                                            },
                                            '.img2': {
                                                display: 'initial'
                                            }
                                        })
                                    }
                                }}
                            >
                                <Icon className="img2">
                                    <FaUpload />
                                </Icon>
                                {formModel.logo ? (
                                    <img src={formModel.logo} width="100px" height="100px" className="img" alt="logo" style={{ borderRadius: '50%' }} />
                                ) : (
                                    <Icon
                                        className="img"
                                        sx={{
                                            color: "#222",
                                            fill: '#222',
                                            fontSize: '1.5rem'
                                        }}
                                    >
                                        <FaSackDollar />
                                    </Icon>
                                )}
                                <input
                                    disabled={!isAdmin}
                                    type="file"
                                    id="fileLoader"
                                    onChange={e => handleFileChange(e)} style={{ display: 'none' }}
                                    accept=".png,.jpeg,.jpg,.svg"
                                />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid md={10} item>
                        <Grid container spacing={2}>
                                <Grid xs={12} sm={12} md={12} item>
                                <StyledDescriptionText>
                                    {formModel.oid && (
                                        <IconButton
                                            onClick={async (data) => {
                                                const type = "text/plain";
                                                var blob = new Blob([formModel.oid], { type });
                                                await navigator.clipboard.write([new ClipboardItem({ [type]: blob })]);
                                            }}
                                        >
                                            <FaRegCopy style={{ fill: '#1c6e3d' }} />
                                        </IconButton>
                                    )}
                                    {t("shared.code")}: {formModel.oid || 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxxx'}
                                </StyledDescriptionText>
                            </Grid>
                            <Grid xs={12} sm={12} md={8} item>
                                <TextField
                                    label={`${t("fundDetailsPage.name")}*`}
                                    name="name"
                                    value={formModel.name || ''}
                                    error={Boolean(formErrorModel?.name)}
                                    helperText={formErrorModel.name}
                                    onChange={handleFieldChange}
                                    InputProps={{
                                        readOnly: !isAdmin
                                    }}
                                />
                            </Grid>
                            <Grid xs={12} sm={12} md={4} item>
                                <TextField
                                    label={`${t("fundDetailsPage.balance")}*`}
                                    onChange={handleFieldChange}
                                    value={formModel.balance || ''}
                                    name="balance"
                                    error={Boolean(formErrorModel?.balance)}
                                    helperText={formErrorModel.balance}
                                    InputProps={{
                                        readOnly: !isAdmin,
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid xs={12} sm={12} md={12} item>
                        <TextField
                            label={`${t("fundDetailsPage.description")}`}
                            name="description"
                            onChange={handleFieldChange}
                            value={formModel.description || ''}
                            error={Boolean(formErrorModel?.description)}
                            helperText={formErrorModel.description}
                                    InputProps={{
                                        readOnly: !isAdmin
                                    }}
                            multiline
                            rows={3}
                        />
                    </Grid>
                    <Grid xs={12} sm={12} md={4} lg={3} item>
                        <DatePicker
                            popperPlacement="right"
                            dateFormat="dd/MM/yyyy"
                            readOnly={!isAdmin}
                            onChange={handleStartDateOnChange}
                            maxDate={formModel.endDate ? moment(formModel.endDate).add(-1, "day").toDate() : null}
                            selected={formModel.startDate ? new Date(formModel.startDate) : null}
                            customInput={(
                                <CustomDateInput
                                    InputProps={{
                                        readOnly: !isAdmin
                                    }}
                                    label={`${t("fundDetailsPage.startDate")}*`}
                                    error={Boolean(formErrorModel?.startDate)}
                                    helperText={formErrorModel.startDate}
                                    clear={handleStartDateClear}
                                />
                            )}
                        />
                    </Grid>
                    <Grid xs={12} sm={12} md={4} lg={3} item>
                        <DatePicker
                            popperPlacement="right"
                            dateFormat="dd/MM/yyyy"
                            readOnly={!isAdmin}
                            minDate={formModel.startDate ? moment(formModel.startDate).add(1, "day").toDate() : null}
                            onChange={handleEndDateOnChange}
                            selected={formModel.endDate ? new Date(formModel.endDate) : null}
                            customInput={(
                                <CustomDateInput
                                    InputProps={{
                                        readOnly: !isAdmin
                                    }}
                                    label={`${t("fundDetailsPage.endDate")}*`}
                                    error={Boolean(formErrorModel?.endDate)}
                                    helperText={formErrorModel.endDate}
                                    clear={handleEndDateClear}
                                />
                            )}
                        />
                    </Grid>
                    <Grid xs={12} sm={12} md={4} lg={6} item>
                        <FormControlLabel
                            sx={{ mt: 1 }}
                            label={`${t("fundDetailsPage.public")}`}
                            control={(
                                <Switch
                                    disabled={!isAdmin}
                                    checked={formModel.public}
                                    onChange={handlePublicSwitchOnChange}
                                />
                            )}
                        />
                    </Grid>
                    <Grid xs={12} sm={12} md={3} item>
                        <TextField
                            sx={{ textAlign: 'right' }}
                            InputProps={{
                                readOnly: !isAdmin,
                                inputProps: {
                                    style: { textAlign: "right" },
                                }
                            }}
                            label={`${t("fundDetailsPage.expectedProfit")}(%)*`}
                            onChange={handleFieldChange}
                            value={formModel.expectedProfit  || ''}
                            name="expectedProfit"
                            error={Boolean(formErrorModel?.expectedProfit)}
                            helperText={formErrorModel.expectedProfit}
                        />
                    </Grid>
                    <Grid xs={12} sm={12} md={3} item>
                        <TextField
                            sx={{ textAlign: 'right' }}
                            InputProps={{
                                readOnly: !isAdmin,
                                inputProps: {
                                    style: { textAlign: "right" },
                                }
                            }}
                            label={`${t("fundDetailsPage.fundCommission")}(%)`}
                            onChange={handleFieldChange}
                            value={formModel.commission  || ''}
                            name="commission"
                            error={Boolean(formErrorModel?.commission)}
                            helperText={formErrorModel.commission}
                        />
                    </Grid>
                    <Grid xs={12} sm={12} md={3} item>
                        <TextField
                            sx={{ textAlign: 'right' }}
                            InputProps={{
                                readOnly: !isAdmin,
                                inputProps: {
                                    style: { textAlign: "right" },
                                }
                            }}
                            label={`${t("fundDetailsPage.costs")}`}
                            onChange={handleFieldChange}
                            value={formModel.costs  || ''}
                            name="costs"
                            error={Boolean(formErrorModel?.costs)}
                            helperText={formErrorModel.costs}
                        />
                    </Grid>
                    <Grid xs={12} sm={12} md={3} item>
                        <TextField
                            InputProps={{
                                readOnly: !isAdmin,
                                inputProps: {
                                    style: { textAlign: "right" },
                                }
                            }}
                            label={`${t("fundDetailsPage.quotesExpenses")}(%)`}
                            name="quotesExpenses"
                            onChange={handleFieldChange}
                            value={formModel.quotesExpenses || ''}
                            error={Boolean(formErrorModel?.quotesExpenses)}
                            helperText={formErrorModel.quotesExpenses}
                        />
                    </Grid>
                    <Grid xs={12} sm={12} md={3} item>
                        <TextField
                            InputProps={{
                                readOnly: !isAdmin,
                                inputProps: {
                                    style: { textAlign: "right" },
                                }
                            }}
                            label={`${t("fundDetailsPage.quotesAmount")}`}
                            name="quotesAmount"
                            onChange={handleFieldChange}
                            value={formModel.quotesAmount  || ''}
                            error={Boolean(formErrorModel?.quotesAmount)}
                            helperText={formErrorModel.quotesAmount}
                        />
                    </Grid>
                    <Grid xs={12} sm={12} md={3} item>
                        <TextField
                            InputProps={{
                                readOnly: !isAdmin,
                                inputProps: {
                                    style: { textAlign: "right" },
                                }
                            }}
                            label={`${t("fundDetailsPage.takeProfitTarget")}(%)`}
                            name="takeProfitTarget"
                            onChange={handleFieldChange}
                            value={formModel.takeProfitTarget  || ''}
                            error={Boolean(formErrorModel?.takeProfitTarget)}
                            helperText={formErrorModel.takeProfitTarget}
                        />
                    </Grid>
                    <Grid xs={12} sm={12} md={3} item>
                        <TextField
                            InputProps={{
                                readOnly: !isAdmin,
                                inputProps: {
                                    style: { textAlign: "right" },
                                },
                            }}
                            label={`${t("fundDetailsPage.stopLoseTarget")}(%)`}
                            name="stopLoseTarget"
                            onChange={handleFieldChange}
                            value={formModel.stopLoseTarget  || ''}
                            error={Boolean(formErrorModel?.stopLoseTarget)}
                            helperText={formErrorModel.stopLoseTarget}
                        />
                    </Grid>
                    {isAdmin && (
                        <Grid md={12} item>
                            <Stack spacing={2} direction="row" justifyContent="end" alignItems="center">
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                    {formModel.oid && (
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            onClick={handleDeleteClick}
                                        >
                                            {t("shared.delete")}
                                        </Button>
                                    )}
                                    <Button onClick={handleSubmitForm} >
                                        {t("shared.save")}
                                    </Button>
                                </Box>
                            </Stack>
                        </Grid>
                    )}
                </Grid>
        </Box>
    );
}
