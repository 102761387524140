import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { useMediaQuery, useTheme } from '@mui/material';
import { ErrorsOutlet, LoginOutlet, ProtectedOutlet, ProtectedSmallOutlet } from '..';
import { routes } from '../../../constants/appRoutes';
import { ConfirmEmailPage, ConfirmForgotPasswordPage, Error500Page, ForgotPasswordPage, FundsPageRoutes, InvitationsPage, LogInPage, RegistrationPage, UserPage, UsersPage, WalletsPageRoutes } from '../../../features';
import { FundsPage } from '../../../features/FundsPage/components/FundsPage';
import { useProfileContext } from '../../contexts';
import { AdminWalletsViewPageRoutes } from '../../../features/AdminWalletsViewPage';

export const AppRoutes = () => {

    const { isAdmin } = useProfileContext();
    const navigate = useNavigate();
    const theme = useTheme();
    const smNoMatches = useMediaQuery(theme.breakpoints.up('md'));

    React.useEffect(() => {
        if (!smNoMatches) {
            navigate('/protected');
        }
    }, [smNoMatches]);

    return (
        <Routes>
            <Route path={routes.loginRoute} element={<LoginOutlet />}>
                <Route index element={<LogInPage />} />
                <Route path={routes.registration} element={<RegistrationPage />} />
                <Route path={routes.forgotPassword} element={<ForgotPasswordPage />} />
                <Route path={routes.confirmForgotPassword} element={<ConfirmForgotPasswordPage />} />
                <Route path={routes.confirmEmail} element={<ConfirmEmailPage />} />
            </Route>
            <Route path={routes.errors} element={<ErrorsOutlet />}>
                <Route index element={<Error500Page />} />
            </Route>
            <Route path={routes.protected} element={smNoMatches ? <ProtectedOutlet /> : <ProtectedSmallOutlet />}>
                <Route index element={<FundsPage />} />
                <Route path={routes.user} element={<UserPage />} />
                {isAdmin && <Route path={routes.users} element={<UsersPage />} />}
                {isAdmin && <Route path={routes.invitations} element={<InvitationsPage />} />}
                {FundsPageRoutes}
                {WalletsPageRoutes}
                {AdminWalletsViewPageRoutes}
            </Route>
        </Routes>
    );
};
