import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { routes } from '../../constants/appRoutes';
import { StyledDescriptionText } from '../../shared/components/Common/StyledDescriptionText';
import { useNotifyContext, useProfileContext } from '../../shared/contexts';
import { ConfirmEmailRequest } from '../../shared/helpers/auth.services';

export const useConfirmEmailPage = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const query = new URLSearchParams(location.search);
    const token = query.get("token");
    const id = query.get("id");
    const email = query.get("email");

    const { signOut } = useProfileContext();
    const notify = useNotifyContext();
    const { t } = useTranslation();

    React.useEffect(() => {
        if (token && id && email) {
            ConfirmEmailRequest({
                data: {
                    id,
                    token,
                    email
                }
            }).then(() => {
                notify.success({ content: t("success:confirmEmailRequest") });
                navigate(`/${routes.protected}/user`);
            }).catch(() => {
                signOut({ noRedirect: true });
                navigate(`/${routes.errors}`);
            })
        } else {
            navigate(`/${routes.errors}`);
        }
    }, []);

    return {
        models: {
            token
        }
    }
}

export const ConfirmEmailPage = () => {

    const { t } = useTranslation();

    const { models } = useConfirmEmailPage();

    return (
        <Box textAlign="center">
            <StyledDescriptionText>
                {models.token ?
                    t("ConfirmEmailPage.loading") :
                    t("ConfirmEmailPage.invalidLink")}
            </StyledDescriptionText>
        </Box>
    );
};
